import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const NotFoundPage = () => {
  return (
    <div style={{ textAlign: 'center' }}>
        <Container maxWidth="md">
            <Box my={4}>
                <Typography variant="h4" >
                  404 - Not Found
                </Typography>
            </Box>
            <Box my={4}>
                <Typography variant="h6" >
                  Sorry, the page you are looking for does not exist.
                </Typography>
            </Box>
        </Container>
    </div>
  );
}

export default NotFoundPage;
