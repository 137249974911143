import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from './AuthContext';

const AuthenticatedRedirect = ({ component: Component }) => {
  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    // Redirect based on role
    if (currentUser.role === 0) {
      return <Navigate to="/" />;
    } else if (currentUser.role === 1) {
      return <Navigate to="/search" />;
    } else {
      // Default redirect if role doesn't match
      return <Navigate to="/" />;
    }
  }

  // If not authenticated, render the component (e.g., LoginPage or SignUpPage)
  return <Component />;
};

export default AuthenticatedRedirect;
