
import React from 'react';
import { CircularProgress, Box } from '@mui/material';
import ProperteeIcon from '../assets/Propertee-Logo-Squared-No-Circle.svg'; // Ensure this is the correct path

const MySVG = () => (
  <img 
    src={ProperteeIcon} 
    alt="Propertee Logo" 
    style={{ width: '40px', height: '40px' }} 
  />
);

const ProperteeCircular = ({ size = 80, thickness = 3.6 }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress
        size={size}
        thickness={thickness}
        sx={{
          color: 'primary.main',
          position: 'absolute',
          zIndex: 1,
        }}
      />
      <MySVG />
    </Box>
  );
};

export default ProperteeCircular;

