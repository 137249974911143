import React, { } from 'react';
import AppointmentManagement from '../components/AppointmentManagement';

function EventPage() {

  return (
    <div style={{ display: 'flex', height: 'var(--router-height)' }}>
      <div style={{ flexGrow: 1 }}>
        <AppointmentManagement />
      </div>
    </div>
  );
}

export default EventPage;

