
import React, { useState, useEffect } from 'react';
import { Slide, Box, Alert, Button } from '@mui/material';

// Define an enum for severity levels
const SeverityLevels = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

const AlertBanner = ({
  open = false,
  message = 'This is an alert!',
  severity = SeverityLevels.INFO,
  buttons = [],
  onClose,
}) => {
  const [bannerOpen, setBannerOpen] = useState(open);

  useEffect(() => {
    setBannerOpen(open);
  }, [open]);

  const handleClose = () => {
    setBannerOpen(false);
    if (onClose) onClose(); // Call external onClose handler if provided
  };

  return (
    <Slide in={bannerOpen} direction="down" mountOnEnter unmountOnExit>
      <Box
        sx={{
          position: 'fixed',
          top: 'var(--header-height)',
          width: '100%',
          zIndex: (theme) => theme.zIndex.appBar - 1,
        }}
      >
        <Alert
          severity={severity}
          onClose={handleClose}
          action={
            <>
              {buttons.map((button, index) => (
                <Button
                  key={index}
                  color="inherit"
                  size="small"
                  onClick={button.onClick}
                >
                  {button.label}
                </Button>
              ))}
            </>
          }
          sx={{
            boxShadow: (theme) => theme.shadows[4],
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
            '& .MuiAlert-message': {
              flexGrow: 1,
            },
          }}
        >
          {message}
        </Alert>
      </Box>
    </Slide>
  );
};

export default AlertBanner;

