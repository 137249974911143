import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../environments/FirebaseConfig';

const functions = getFunctions(app, 'australia-southeast1');

export const handlePayment = async (product, email, firstname, lastname) => {
    try {
        // Dynamically load Stripe
        const { loadStripe } = await import('@stripe/stripe-js');
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

        const createStripeCheckoutSession = httpsCallable(functions, 'createStripeCheckoutSession');

        createStripeCheckoutSession({
            priceId: product.prices[0].id, 
            successUrl: 'https://propertee.org/success?session_id={CHECKOUT_SESSION_ID}', 
            cancelUrl: 'https://propertee.org/cancel?session_id={CHECKOUT_SESSION_ID}', 
            email: email, 
            firstname: firstname, 
            lastname: lastname,
        })
        .then(async (result) => {
            console.log(result.data);
            await stripe.redirectToCheckout({ sessionId: result.data.sessionId });
        })
        .catch((error) => {
            console.error(error);
        });
    } catch (error) {
        console.error('Payment error:', error);
    }
};

export const editSubscription = async () => {
    try {
        const createCustomerPortalSession = httpsCallable(functions, 'createCustomerPortalSession');

        createCustomerPortalSession()
        .then((result) => {
            const { url } = result.data;
            window.location.href = url;
        })
        .catch((error) => {
            console.error('Error redirecting to customer portal:', error);
        });
    } catch (error) {
        console.error('Error initializing subscription management:', error);
    }
};
