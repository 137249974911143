import React from 'react';
import { Button, Box, Container, Typography, Paper, Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../assets/propertee-logo.svg';

const AboutPage = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        height: 'var(--router-height)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pb: 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          width: '100%',
          gap: 4,
        }}
      >
        {/* About Us Box */}
        <Paper
          elevation={3}
          sx={{
            p: 4,
            flex: 1,
            my: 2,
          }}
        >
          <Typography variant="h4" gutterBottom>
            About Us
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Typography variant="body1" paragraph>
            Welcome to Propertee! We are committed to finding better, more efficient ways for people to navigate the real estate landscape. Founded in 2024, Propertee has been at the forefront of innovation in the real estate industry.
          </Typography>
          <Typography variant="body1" paragraph>
            Our service is designed to help you find the perfect home, whether you are looking to buy or sell. We are dedicated to providing the best possible experience for our users. Our team is constantly working to improve our service, and we accept the challenge to take on the big players in the industry with a small but capable and dedicated team. Our technology aims to be the most user-friendly and efficient way to buy and sell real estate assets, making the convoluted process more streamlined and convenient.
          </Typography>
          <Typography variant="body1" paragraph>
            At Propertee, we prioritize customer satisfaction and strive to continue improving, evolving with our users and their needs. Thank you for choosing us, and we look forward to helping you sell your valued assets and find your new home. When you think property, think Propertee.
          </Typography>
          {/* Square Logo at the Bottom */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <img
              src={logo}
              alt="Propertee Logo"
              style={{ height: '80px', border: '4px solid #6699CC' }}
            />
          </Box>
        </Paper>

        {/* Privacy Policy and Terms Box */}
        <Paper
          elevation={3}
          sx={{
            p: 3,
            flex: 1,
            my: 2,
          }}
        >
          <Box>
            <Typography variant="h4" gutterBottom>
              Privacy Policy
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="body1" paragraph>
              Our Privacy Policy is designed to help you understand how we collect, use, and safeguard the information you provide to us and to assist you in making informed decisions when using our Service.
            </Typography>
            <Typography variant="body1" paragraph>
              Please click the button below to view our Privacy Policy.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button
                variant="containedAccent"
                component={RouterLink}
                to="/privacy-policy"
                sx={{ width: '100%' }}
              >
                View Privacy Policy
              </Button>
            </Box>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom>
              Terms of Use
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="body1" paragraph>
              Our Terms of Use are designed to help you understand the rules and regulations for the use of our Service.
            </Typography>
            <Typography variant="body1" paragraph>
              Please click the button below to view our Terms of Use.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button
                variant="containedAccent"
                component={RouterLink}
                to="/terms"
                sx={{ width: '100%' }}
              >
                View Terms
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default AboutPage;

