import React from 'react';
import { Box, Container, Typography, Paper, Divider } from '@mui/material';

const TermsPage = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        height: 'var(--router-height)',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            my: 2,
          }}
        >
          <Typography variant="h4" gutterBottom>
            Terms of Use
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Typography variant="body1" paragraph>
            These Propertee (“Products”) Terms of Use (the “Agreement”) govern the use of Propertee. The BNK Technology Pty Ltd ("BNK Technology") and users agree and acknowledge 
            that this Agreement will apply for the use of Propertee. “Users” refer to Agents and advisers who have licensed use of the Products.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            1. Policies.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong> a. General Policies.</strong> Users shall use the Products subject to the Propertee Privacy Policy, the Terms of Use, and other policies and 
            requirements published by Propertee from time to time (collectively, “Policies”). The Policies are incorporated by reference herein and Propertee 
            may modify the Policies at any time. To the extent there is an inconsistency between a term in this Agreement and a term in the Policies, the term 
            in this Agreement will govern. Propertee reserves the right to refuse the supply of Products for any reason and to discontinue supplying any Product 
            and/or terminate a product service in the event that user violates the Policies or this Agreement.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            2. Programs.
          </Typography>
          <Typography variant="body1" paragraph>
            BNK Technology shall, on a month to month basis, provide to all Users:
          </Typography>
          <Typography variant="body1" paragraph>
            • Unlimited use of the Propertee QR code buyer registration check-in system. This system will assist users to keep a digital record of home open attendees, 
            which can be managed though Propertee.
          </Typography>
          <Typography variant="body1" paragraph>
            • Unlimited use of Propertee's automated offer management program
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            3. Ad Materials; Advertiser Information.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>a. License in Ad Materials and Advertiser Information.</strong> Advertiser hereby grants to BNK Technology a royalty-free, perpetual, non-exclusive, fully 
            sublicenseable right and license to reproduce, modify, display, distribute, publicly perform, create derivative works from, store, and otherwise use and exploit, 
            all Ad Materials in any form, media, software or technology of any kind to be used only on or in connection with Services. For purposes of this Agreement, 
            “Ad Materials” includes all data and other information provided by Advertiser to BNK Technology, in any form, including without limitation: (i) the property 
            listings for properties that Advertiser provides to BNK Technology and all corresponding materials (“Listings”) and any other materials submitted to Propertee 
            Group by Advertiser for inclusion in an Ad, including, without limitation, photos, videos, information, URLs, and other content, whether generated by or for 
            Advertiser; and (ii) the web sites and landing pages to which Ad Materials link or direct users, and the advertised properties and services on such pages. 
            Advertiser additionally grants BNK Technology the right to create derivative works of information and data about Advertiser that Advertiser submits to the 
            Services (including, without limitation, via the Propertee CRM) and to reproduce, distribute and otherwise exploit such derivative works, so long as Advertiser 
            is not identified or identifiable as the source of specific information. For clarity, BNK Technology owns all right, title and interest in all data that 
            BNK Technology collects about Advertiser’s use of the Services and user interaction with Ads, leads and pricing, as well as any derivative works created pursuant 
            to the prior sentence.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
           4. Termination.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>a. Non-refundable. All Propertee purchases are non-refundable.</strong>
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>b. Term.</strong> The Products are subject to a one month Term, which will auto-renew each month until the subscription is updated or terminated by User or BNK Technology.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>c. No Refund; Fee for Early Termination.</strong> BNK Technology may, in its sole discretion, change the price to be paid for the Products effective as of the beginning of 
            any monthly renewal term. Upon providing notice to BNK Technology, Users may terminate the Services before the end of the Term, however: (A) prepaid amounts are not 
            refundable; (B) Advertiser will be billed the full amount for the then current Term (including, without limitation, a one month Term).
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>d. Upgrades/Downgrades.</strong> Users who have purchased a Propertee subscription may upgrade their subscription at any time, subject to availability and then current pricing.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            5. Prohibited Uses.
          </Typography>
          <Typography variant="body1" paragraph>
            Propertee Users shall not, and shall not authorize any party to: (a) generate automated, fraudulent or otherwise invalid impressions, inquiries, conversions, 
            clicks or other actions for Advertiser’s own advertising purposes or any other advertisements on the BNK Technology Sites or the Licensee Sites; (b) use any automated 
            means of scraping or data extraction to collect BNK Technology advertising related information from any BNK Technology Site or Licensee Site except as expressly 
            permitted by BNK Technology in writing; or (c) advertise anything illegal or engage in any illegal or fraudulent business practice.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            6. Disclaimer and Limitation of Liability. 
          </Typography>
          <Typography variant="body1" paragraph>
            BNK TECHNOLOGY PROVIDES THE PROGRAMS “AS IS,” “WITH ALL FAULTS” AND “AS AVAILABLE,” AND THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY, AND EFFORT 
            IS WITH ADVERTISER. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, BNK TECHNOLOGY AND ITS SUPPLIERS MAKE NO REPRESENTATIONS, WARRANTIES OR CONDITIONS, EXPRESS OR 
            IMPLIED. BNK TECHNOLOGY, ITS LICENSEES, AND ITS SUPPLIERS EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES OR CONDITIONS, EXPRESS, STATUTORY AND IMPLIED, INCLUDING (A) WARRANTIES 
            OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, WORKMANLIKE EFFORT, ACCURACY, TITLE, QUIET ENJOYMENT, NO ENCUMBRANCES, NO LIENS AND NON-INFRINGEMENT, (B) 
            WARRANTIES OR CONDITIONS ARISING THROUGH COURSE OF DEALING OR USAGE OF TRADE, AND (C) WARRANTIES OR CONDITIONS OF UNINTERRUPTED OR ERROR-FREE ACCESS OR USE. Without 
            limitation, BNK Technology disclaims all guarantees regarding positioning, levels, quality, or timing of: (i) availability and delivery of any impressions or materials 
            on any BNK Technology Site or Licensee Site, or section thereof; (ii) click through rate; (iii) click throughs; on the BNK Technology Site(s) or the Licensee Site(s). 
            NEITHER PARTY WILL BE LIABLE FOR ANY CONSEQUENTIAL, SPECIAL, INDIRECT, EXEMPLARY, OR PUNITIVE DAMAGES (INCLUDING LOSS OF PROFITS, REVENUE, INTEREST, GOODWILL, LOSS OR 
            CORRUPTION OF DATA OR FOR ANY LOSS OR INTERRUPTION TO ADVERTISER’S BUSINESS) WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL THEORY, EVEN IF ADVISED 
            OF THE POSSIBILITY OF SUCH DAMAGES AND NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY; AND EACH PARTY’S AGGREGATE LIABILITY TO THE OTHER IS LIMITED 
            TO AMOUNTS PAID OR PAYABLE TO BNK TECHNOLOGY BY USER FOR THE PRODUCTS GIVING RISE TO THE CLAIM. Except for payment obligations, neither party is liable for failure or 
            delay resulting from a condition beyond the reasonable control of the party, including but not limited to acts of God, government, terrorism, natural disaster, labor 
            conditions and power failures.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            7. Payment.
          </Typography>
          <Typography variant="body1" paragraph>
            Users shall be responsible for all charges as provided via their account, and shall pay all charges in Australian Dollars. For purchases directly from BNK Technology, 
            charges are made against Users's credit card at the time of purchase and on a monthly basis for ongoing subscriptions. Nothing in this Agreement may obligate Propertee
            Group to extend credit to any party. Users acknowledges and agrees that any credit card and related billing and payment information that Users provide to Propertee 
            Group may be shared by BNK Technology with companies who work on BNK Technology’s behalf, such as payment processors and/or credit agencies, solely for the purposes 
            of checking credit, effecting payment to BNK Technology and servicing User’s account. BNK Technology may also provide information in response to valid legal process, 
            such as subpoenas, search warrants and court orders, or to establish or exercise its legal rights or defend against legal claims. BNK Technology shall not be liable 
            for any use or disclosure of such information by such third parties.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            8. Changes to Terms of the Agreement and or the Products.
          </Typography>
          <Typography variant="body1" paragraph>
            BNK Technology may change the terms of this Agreement at any time upon notice and without liability. BNK Technology may give notice as described in Section 10 below, 
            or BNK Technology may give notice by updating the terms on the web page located at http://www.propertee.com/terms. BNK Technology further reserves the right 
            to change or discontinue any or all of the products at any time with or without notice.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            9. Indemnification.
          </Typography>
          <Typography variant="body1" paragraph>
            Users shall indemnify and defend BNK Technology, its authorized licensees, suppliers, agents, affiliates, and licensors from any third party claim or liability arising 
            out of: (i) The Products, (ii) users’s violation of federal, state, local or any other laws or regulations; and (iii) Users’s breach of this Agreement, their subscription, 
            or any other applicable terms and conditions, including but not limited to the BNK Technology Terms of Use.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            10. Consent to Receipt and Recording of Communications.
          </Typography>
          <Typography variant="body1" paragraph>
            User agrees that BNK Technology, or a third party acting on behalf of BNK Technology, may email, call and/or send text messages to the email address and telephone number(s) 
            provided by User, or via web or in-app features, including calls and text messages using an automatic telephone dialing system and/or an artificial or prerecorded voice 
            (“Other Messages”). User further agrees that such emails, calls and/or text messages may constitute advertising or telemarketing, e.g. BNK Technology may email, call, or text 
            with information about new service offerings available to User. user understands that agreeing to receive Other Messages that constitute advertising or telemarketing is not a 
            condition to purchase. User further agrees that calls between User and BNK Technology employees may be recorded for quality control, training, and other internal business purposes. 
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            11. Dispute Resolution.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>a. Binding Arbitration.</strong> You and BNK Technology agree to resolve any claims relating to these this Agreement or the Products (collectively, “Disputes”) through 
            final and binding arbitration, except as otherwise specifically provided herein.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>b. Waiver of Class Action.</strong> You acknowledge and agree that you and BNK Technology are each waiving the right to a trial by jury or to participate as a 
            plaintiff or class member in any purported class action lawsuit, class-wide arbitration, private attorney-general action, or any other representative proceeding. Further, unless 
            both you and BNK Technology otherwise agree in writing, the arbitrator may not consolidate more than one person’s claims, and may not otherwise preside over any form of any 
            class or representative proceeding. If this specific paragraph is held unenforceable, then the entirety of this “Dispute Resolution” section will be deemed void. Except as 
            provided in the preceding sentence, this “Dispute Resolution” section will survive any termination of this Agreement.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>c. Arbitration Rules and Governing Law.</strong> Any dispute, or claim arising out of, relating to or in connection with these Terms or the Services, including any question 
            regarding its existence, validity or termination, shall be resolved by arbitration in accordance with the ACICA Arbitration Rules. The seat of arbitration shall be Perth, Australia. 
            The language of the arbitration shall be English
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>d. Notice; Informal Dispute Resolution.</strong> A party who intends to seek arbitration must first send written notice to the other by email mail. Your notice must include 
            (a) your name, postal address, telephone number, email address, (b) a description in reasonable detail of the nature or basis of the Dispute, and (c) the specific relief that you 
            are seeking. BNK Technology’s notice to you will be sent electronically to the email address BNK Technology has on file associated with your BNK Technology account, and will include 
            (a) BNK Technology’s name, postal address, telephone number and an email address at which BNK Technology can be contacted with respect to the Dispute, (b) a description in reasonable 
            detail of the nature or basis of the Dispute, and (c) the specific relief that BNK Technology is seeking.
          </Typography>
          <Typography variant="body1" paragraph>
            If you and BNK Technology cannot agree how to resolve the Dispute within thirty (30) days after the date notice is received by the applicable party, then either you or BNK Technology 
            may, as appropriate and in accordance with this Agreement, commence an arbitration proceeding.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>e. Fees.</strong> Users are  responsible to pay any costs unless otherwise determined by ACICA arbitration rules.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>f. Changes.</strong>  If BNK Technology changes this “Dispute Resolution” section after the date you last accepted this Agreement (or accepted any subsequent changes to this 
            Agreement), you may reject any such change by sending BNK Technology written notice (including by email) within 30 days of the date such change became effective, as indicated in the 
            “Last Updated” date above or in the date of BNK Technology’s email to you notifying you of such change. By rejecting any change, you are agreeing that you will resolve any Dispute 
            between you and BNK Technology in accordance with the provisions of this “Dispute Resolution” section as of the date you last accepted this Agreement (or accepted any subsequent 
            changes to this Agreement).
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>g. Exceptions to Agreement to Arbitrate.</strong> Either party may bring a lawsuit solely for injunctive or other equitable relief in a court of competent jurisdiction to 
            prevent the actual or threatened infringement, misappropriation or violation of a party’s copyrights, trademarks, trade secrets, patents, or other intellectual property rights 
            without first engaging in arbitration or the informal dispute-resolution process described above. If the agreement to arbitrate is found not to apply to you or your claim, you 
            agree to the exclusive jurisdiction of the state and federal courts in Perth, Western Australia to resolve your claim.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            12. Miscellaneous. 
          </Typography>
          <Typography variant="body1" paragraph>
            This Agreement is governed by the laws of the State of Western Australia, without giving effect to its conflict of laws provisions. Each party agrees to submit to exclusive 
            jurisdiction and venue in the state and federal courts sitting in Perth, Western Australia for any actions for which the parties retain the right to seek injunctive or other 
            equitable relief. In any dispute arising under this Agreement, the prevailing party will be entitled to legal fees and expenses. This Agreement, including the corresponding 
            Ad Order(s), constitutes the entire and exclusive agreement between the parties with respect to the subject matter hereof, and supersedes and replaces any other agreements, 
            terms and conditions applicable to the subject matter hereof. No statements or promises have been relied upon in entering into this Agreement except as expressly set forth 
            herein, and any conflicting or additional terms contained in any other documents (e.g. reference to a purchase order number) or oral discussions are void. User may grant 
            approvals, permissions, extensions and consents by email, but any modifications by User to the Agreement must be agreed upon in a writing executed by both parties. Any 
            unenforceable provisions will be modified to reflect the parties’ intention and only to the extent necessary to make them enforceable, and remaining provisions of the Agreement 
            will remain in full effect. User may not assign any of its rights hereunder and any such attempt is void. BNK Technology and user are independent contractors, not legal 
            partners or agents. In the event that this Agreement or the applicable Services are terminated, BNK Technology shall not be obligated to return any materials to User.
          </Typography>
          
          <Divider sx={{ mb: 2 }} />
          <Typography variant="body2" gutterBottom sx={{ mt: 4 }}>
            Last Updated: 29th October 2024
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default TermsPage;

