
import React from 'react';
import { Box, Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UploadIcon from '@mui/icons-material/Upload';

const UploadControlBar = ({ onBack, onUpload }) => {
  return (
    <Box display="flex" height="30px" alignItems="center" p={2} bgcolor="#ffffff" boxShadow={1}>
      <IconButton edge="start" color="inherit" onClick={onBack} aria-label="back">
        <ArrowBackIcon />
      </IconButton>
      <Box flexGrow={1} />
      <Button
        variant="contained"
        color="primary"
        startIcon={<UploadIcon />}
        onClick={onUpload}>
        Publish
      </Button>
    </Box>
  );
};

export default UploadControlBar;

