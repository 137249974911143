import React, { useState, useEffect } from 'react';
import { app } from '../environments/FirebaseConfig';
import { 
  EmailAuthProvider, 
  getAuth, 
  signInWithEmailAndPassword, 
  signOut, 
  createUserWithEmailAndPassword, 
  updatePassword, 
  reauthenticateWithCredential,
  sendEmailVerification,
} from "firebase/auth";
import AuthContext from './AuthContext';
import { saveUserData, saveGuestSignUpUserData, getUserData, updateUserData } from './FirestoreFunctions';
import { getFunctions, httpsCallable } from 'firebase/functions';


const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true); 
  const auth = getAuth(app);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userData = await getUserData(app, user.uid);
        setCurrentUser({ ...user, ...userData });
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  const login = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const userData = await getUserData(app, auth.currentUser.uid);
      setCurrentUser({ ...auth.currentUser, ...userData });
    } catch (error) {
      console.error("Error logging in: ", error);
      throw error;
    }
  };

  const signup = async (email, password, firstname, lastname, signUpRole, licenseNumber, guestCheckInDocId, homeOpenId, phoneNumber) => {
    const role = signUpRole ? signUpRole : 0;
    const approved = false;

    if(role === 1) {
      try {
        await createUserWithEmailAndPassword(auth, email, password);
        await sendEmailVerification(auth.currentUser);
        await saveUserData(app, auth.currentUser.uid, { firstname, lastname, role, licenseNumber, approved, phoneNumber });
        setCurrentUser({ ...auth.currentUser, firstname, lastname, role, phoneNumber });
      } catch (error) {
        console.error("Error signing up: ", error);
        throw error;
      }
    }
    else {
      if (guestCheckInDocId && homeOpenId) {
        try {
          await createUserWithEmailAndPassword(auth, email, password);
          await sendEmailVerification(auth.currentUser);
          await saveGuestSignUpUserData(app, auth.currentUser.uid, { firstname, lastname, role, approved, phoneNumber }, guestCheckInDocId, homeOpenId);
          setCurrentUser({ ...auth.currentUser, firstname, lastname, role, phoneNumber });
        } catch (error) {
          console.error("Error signing up: ", error);
          throw error;
        }
      }
      else {
        try {
          await createUserWithEmailAndPassword(auth, email, password);
          await sendEmailVerification(auth.currentUser);
          await saveUserData(app, auth.currentUser.uid, { firstname, lastname, role, phoneNumber });
          setCurrentUser({ ...auth.currentUser, firstname, lastname, role, phoneNumber });
        } catch (error) {
          console.error("Error signing up: ", error);
          throw error;
        }
      }
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
    } catch (error) {
      console.error("Error logging out: ", error);
      throw error;
    }
  };

  const updateUser = async (firstname, lastname, phoneNumber) => {
    try {
      await updateUserData(auth.currentUser.uid, { firstname, lastname, phoneNumber });
      //await updatePhoneNumber(app, auth.currentUser, phoneNumber); We will need the verification side to be included.
    
      const userData = await getUserData(app, auth.currentUser.uid);
    
      setCurrentUser({ ...auth.currentUser, ...userData, firstname, lastname, phoneNumber });
    } catch (error) {
      console.error("Error updating user: ", error);
      throw error;
    }
  };

  const changePassword = async (newPassword, currentPassword) => {
    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
  
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      console.log("Password updated successfully!");
    } catch (error) {
      console.error("Password change failed:", error.message);
      throw error;
    }
  };

  const resendVerificationEmail = async () => {
    try {
      const user = auth.currentUser;
  
      if (!user) {
        throw new Error("No authenticated user found.");
      }
  
      if (user.emailVerified) {
        console.log("User's email is already verified.");
        return { status: "already_verified", message: "Your email is already verified." };
      }
  
      // Send the email verification
      await sendEmailVerification(user);
      console.log("Verification email sent successfully.");
      return { status: "success", message: "Verification email sent. Check your inbox." };
    } catch (error) {
      console.error("Error sending verification email:", error);
      throw new Error("Failed to resend verification email. Please try again.");
    }
  };

  const deleteUser = async (password) => {
    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(user.email, password);

      await reauthenticateWithCredential(user, credential);

      // Call the deleteAccount Cloud Function
      const functions = getFunctions(app, 'australia-southeast1');
      const deleteAccount = httpsCallable(functions, 'deleteUser');
      await deleteAccount();

      console.log("User deleted successfully!");
    } catch (error) {
      console.error("Error deleting user:", error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, login, signup, logout, updateUser, changePassword, resendVerificationEmail, deleteUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;