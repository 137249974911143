import { createContext } from 'react';

const AuthContext = createContext({
  currentUser: null,
  firstname: null,
  lastname: null,
  role: null, 
  approved: null,
  login: () => {},
  signup: () => {},
  logout: () => {},
  updateUser: () => {},
  changePassword: () => {},
  resendVerificationEmail: () => {},
  deleteUser: () => {},
});

export default AuthContext;
