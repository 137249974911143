import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  Container,
  TextField,
  Button,
  Grid,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Pagination,
} from '@mui/material';
import SearchResultTile from '../components/SearchResultTile';
import EditHome from '../components/EditHome';
import CreateHome from '../components/CreateHome';
import { getPropertiesByAgentId } from '../utilities/FirestoreFunctions';
import AuthContext from '../utilities/AuthContext';

import ProperteeCircularProgress from '../components/ProperteeCircular.js';

const SearchPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [filterOption, setFilterOption] = useState('default');
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 50;

  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [shouldRefetch, setShouldRefetch] = useState(true);

  const [currentComponent, setCurrentComponent] = useState('');
  const [editProperty, setEditProperty] = useState(null);

  const [additionalFilter, setAdditionalFilter] = useState('');

  // const fetchProperties = async () => {
  //   setLoading(true);
  //   getPropertiesByAgentId(currentUser.uid)
  //     .then((properties) => {
  //       const propertyData = properties.map((property) => ({
  //         ...property, // Include all property fields
  //         id: property.id,
  //         title: property.title,
  //         imageUrl: property.imgUrl,
  //         shortDescription: property.shortDescription,
  //       }));
  //
  //       setSearchResults(propertyData);
  //       setShouldRefetch(false);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setShouldRefetch(false);
  //       setLoading(false);
  //     });
  // };
  //
  // useEffect(() => {
  //   if (shouldRefetch) {
  //     fetchProperties();
  //   }
  // }, [shouldRefetch]);

  const addProperty = (newProperty) => {
    setSearchResults((prevResults) => [...prevResults, newProperty]);
  };

  const updatePropertyInList = (updatedProperty) => {
    setSearchResults((prevResults) =>
      prevResults.map((prop) => (prop.id === updatedProperty.id ? updatedProperty : prop))
    );
  };

  const removeProperty = (propertyId) => {
    setSearchResults((prevResults) => prevResults.filter((prop) => prop.id !== propertyId));
  };

  const fetchProperties = useCallback(async () => {
    setLoading(true);
    getPropertiesByAgentId(currentUser.uid)
      .then((properties) => {
        const propertyData = properties.map((property) => ({
          ...property, // Include all property fields
          id: property.id,
          title: property.title,
          imageUrl: property.imgUrl,
          shortDescription: property.shortDescription,
        }));

        setSearchResults(propertyData);
        setShouldRefetch(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setShouldRefetch(false);
        setLoading(false);
      });
  }, [currentUser.uid]); // Add dependencies here (e.g., currentUser.uid)

  useEffect(() => {
    if (shouldRefetch) {
      fetchProperties();
    }
  }, [shouldRefetch, fetchProperties]);

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const handlePageChange = (value) => { //NOTE: this use to have event in here but it was not used anywhere
    setCurrentPage(value);
  };

  const handleOnClick = (property) => {
    setEditProperty(property);
    setCurrentComponent('EditListing');
  };

  const renderComponent = () => {
    switch (currentComponent) {
      case 'EditListing':
        return (
          <EditHome
            property={editProperty}
            setCurrentComponent={setCurrentComponent}
            updatePropertyInList={updatePropertyInList}
            removeProperty={removeProperty}
          />
        );
      case 'CreateListing':
        return (
          <CreateHome
            setCurrentComponent={setCurrentComponent}
            addProperty={addProperty}
          />
        );
      default:
        return (
          <Container maxWidth="lg" sx={{ py: 2 }}>
            {/* Page Title */}
            <Typography variant="h4" align="center" gutterBottom sx={{ mb: 2 }}>
              Search Your Listings
            </Typography>
            <Grid container spacing={2} alignItems="center">

              <Grid item xs={12} md={6}>
                <TextField
                  label="Search"
                  variant="outlined"
                  fullWidth
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Sort</InputLabel>
                  <Select
                    value={filterOption}
                    onChange={(e) => setFilterOption(e.target.value)}
                    label="Sort"
                  >
                    <MenuItem value="default">Default</MenuItem>
                    <MenuItem value="priceLowToHigh">Price Low to High</MenuItem>
                    <MenuItem value="priceHighToLow">Price High to Low</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={2}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Price Filter</InputLabel>
                  <Select
                    value={additionalFilter}
                    onChange={(e) => setAdditionalFilter(e.target.value)}
                    label="Sale Filter"
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="priced">Priced</MenuItem>
                    <MenuItem value="openToOffers">Open to Offers</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={2}>
                <Button
                  variant="containedAccent"
                  sx={{ width: { xs: '100%', sm: 'auto' } }}
                  onClick={() => setCurrentComponent('CreateListing')}
                >
                  Create Home
                </Button>
              </Grid>
            </Grid>


            {/* Search Results */}
            <Grid container spacing={3} sx={{ mt: 2 }}>
              {currentResults.length === 0 ? (
                <Typography variant="h6" align="center" sx={{ width: '100%' }}>
                  No results found
                </Typography>
              ) : (
                currentResults.map((property) => (
                  <Grid item xs={12} sm={6} md={4} key={property.id}>
                    <SearchResultTile result={property} handleOnClick={handleOnClick} />
                  </Grid>
                ))
              )}
            </Grid>

            {/* Pagination */}
            {totalPages > 1 && (
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}
              />
            )}
          </Container>
        );
    }
  };

  // Get current page results
  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;

  // Apply search term and filters to searchResults
  const filteredResults = searchResults.filter((property) => {
    // Create a regex for the search term, escaping special characters
    const regex = new RegExp(searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i'); // 'i' for case-insensitive

    // Match search term against title and address
    const matchesSearchTerm =
      regex.test(property.title) ||
      regex.test(property.address);

    // Apply additional filters if needed
    let matchesFilter = true;

    if (additionalFilter === 'priced') {
      matchesFilter = property.priceParameter !== '*';
    } else if (additionalFilter === 'openToOffers') {
      matchesFilter = property.priceParameter === '*';
    }

    return matchesSearchTerm && matchesFilter;
  });

  // Sort results based on filterOption
  const sortedResults = [...filteredResults].sort((a, b) => {
    if (filterOption === 'priceLowToHigh') {
      return a.price - b.price;
    } else if (filterOption === 'priceHighToLow') {
      return b.price - a.price;
    } else {
      return 0; // Default order
    }
  });

  const currentResults = sortedResults.slice(indexOfFirstResult, indexOfLastResult);
  const totalPages = Math.ceil(filteredResults.length / resultsPerPage);

  return (
    <div
    >
      {loading && (
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'var(--router-height)' }}>
          {/* <div */}
          {/*   style={{ */}
          {/*     position: 'fixed', */}
          {/*     top: '50%', */}
          {/*     left: '50%', */}
          {/*     transform: 'translate(-50%, -50%)', */}
          {/*     width: '100%', */}
          {/*     height: '100%', */}
          {/*   }} */}
          {/* > */}
          <ProperteeCircularProgress />
        </Box>
      )}
      {!loading && renderComponent()}
    </div>
  );
};

export default SearchPage;
