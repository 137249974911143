import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';

const SearchResultTile = ({ result, handleOnClick }) => {
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    if (result.firstImageObject instanceof File) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);
      };
      reader.readAsDataURL(result.firstImageObject);
    } else {
      setImageSrc(result.imageUrl);
    }
  }, [result.firstImageObject, result.imageUrl]);

  return (
    <Card onClick={() => handleOnClick(result)} sx={{ cursor: 'pointer' }}>
      {imageSrc && (
        <CardMedia
          component="img"
          height="200"
          image={imageSrc}
          alt={result.title}
        />
      )}
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {result.title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {result.shortDescription}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default SearchResultTile;
