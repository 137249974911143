import React, { useState, useEffect, useContext } from 'react';
import { Alert, Snackbar, Container, Paper, TextField, Button, Typography, Box, Grid, Divider, InputAdornment } from '@mui/material';
import AuthContext from '../utilities/AuthContext';
import { editSubscription } from '../utilities/StripeProvider';
import { useNavigate } from 'react-router-dom';

import ProperteeCircularProgress from '../components/ProperteeCircular.js';

const UserPage = () => {
  const [userDetails, setUserDetails] = useState({
    firstname: '',
    lastname: '',
    email: '',
    confirmEmail: '',
    phone: '',
    licenseNumber: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [preEditDetails, setPreEditDetails] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    licenseNumber: '',
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', // 'success', 'error', 'warning', 'info'
  });

  const [isEditing, setIsEditing] = useState(false);
  const [passwordIsEditing, setPasswordIsEditing] = useState(false);
  const [deleteAccountIsEditing, setDeleteAccountIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const { currentUser, logout, updateUser, changePassword, deleteUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchedUserDetails = {
      firstname: currentUser.firstname || '',
      lastname: currentUser.lastname || '',
      email: currentUser.email || '',
      confirmEmail: '',
      phone: '',
      licenseNumber: currentUser.licenseNumber || '',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    };

    const fetchedPhone = currentUser.phoneNumber || '';

    let displayPhone = fetchedPhone;

    if (fetchedPhone) {
      let phoneDigits = fetchedPhone.replace('+61', '').replace(/\D/g, '');

      if (phoneDigits.length === 9) {
        displayPhone = phoneDigits.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
      } else {
        displayPhone = fetchedPhone;
      }
    }

    fetchedUserDetails.phone = displayPhone;

    setUserDetails(fetchedUserDetails);
    setLoading(false);
  }, [currentUser]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleEditClick = () => {
    if (!isEditing) {
      // Before starting editing, remove +61 prefix and spaces for input
      let phoneInputValue = userDetails.phone;
      if (phoneInputValue.startsWith('+61')) {
        phoneInputValue = phoneInputValue.slice(3).replace(/\s+/g, '');
      }
      setUserDetails({ ...userDetails, phone: phoneInputValue });
    }
    setPreEditDetails({ ...userDetails });
    setIsEditing(true);
  };

  const handlePasswordEditClick = () => {
    setPasswordIsEditing(true);
  }

  const handleDeleteEditClick = () => {
    setDeleteAccountIsEditing(true);
  }

  const handleSubmit = () => {
    const fullPhoneNumber = '+61' + userDetails.phone.replace(/\s+/g, '');

    const phoneDigits = userDetails.phone.replace(/\s+/g, '');

    const isValid = /^\d{9}$/.test(phoneDigits);

    const formattedPhone = phoneDigits.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');

    if (isValid) {
      updateUser(userDetails.firstname, userDetails.lastname, fullPhoneNumber);
      setSnackbar({
        open: true,
        message: 'Successfully updated user details!',
        severity: 'success',
      });
      setUserDetails({ ...userDetails, phone: formattedPhone });
      setIsEditing(false);
    } else {
      setSnackbar({
        open: true,
        message: 'Invalid phone number. Please enter a valid Australian phone number with 9 digits.',
        severity: 'error',
      });
    }
  };

  const handlePasswordSubmit = async () => {
    if (userDetails.newPassword !== userDetails.confirmPassword) {
      setSnackbar({
        open: true,
        message: 'Passwords do not match. Please try again.',
        severity: 'error',
      });
      return;
    } else if (userDetails.newPassword.length < 6) {
      setSnackbar({
        open: true,
        message: 'Password must be at least 6 characters long.',
        severity: 'error',
      });
      return;
    } else {
      try {
        await changePassword(userDetails.newPassword, userDetails.currentPassword);
        setSnackbar({
          open: true,
          message: 'Password changed successfully!',
          severity: 'success',
        });
        setPasswordIsEditing(false);

        setUserDetails({
          ...userDetails,
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
      } catch (error) {
        let errorMessage = 'Password change failed. Please try again.';
        if (error.code === 'auth/wrong-password' || error.code === 'auth/invalid-credential') {
          errorMessage = 'Incorrect current password. Please try again.';
        } else if (error.code === 'auth/weak-password') {
          errorMessage = 'The new password is too weak. Please choose a stronger password.';
        }
        setSnackbar({
          open: true,
          message: errorMessage,
          severity: 'error',
        });
      }
    }
  };

  const handleDeleteAccountSubmit = async () => {
    try {
      if (userDetails.confirmEmail !== currentUser.email) {
        setSnackbar({
          open: true,
          message: 'Please enter your correct email to confirm.',
          severity: 'error',
        });
        return;
      }

      await deleteUser(userDetails.currentPassword);

      // On success, sign out and navigate away
      await logout();
      navigate('/');
    } 
    catch (error) {
      console.error("Error deleting account: ", error);
      setSnackbar({
        open: true,
        message: 'Account deletion failed. Please check your credentials and try again.',
        severity: 'error',
      });
    }

    setDeleteAccountIsEditing(false);
  };

  const handleSubscribe = () => {
    navigate('/payments');
  };

  const handleEditSubscription = () => {
    setLoading(true);
    editSubscription(currentUser.subscriptionId);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setUserDetails(preEditDetails);
  };

  const handlePasswordCancel = () => {
    setUserDetails({ ...userDetails, newPassword: '', confirmPassword: '', currentPassword: '' });
    setPasswordIsEditing(false);
  };

  const handleCancelDelete = () => {
    setUserDetails({ ...userDetails, confirmEmail: '', currentPassword: '' });
    setDeleteAccountIsEditing(false);
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'var(--router-height)' }}>
        <ProperteeCircularProgress />
      </div>
    );
  };

  return (
    <div>
      <Container
        maxWidth="lg"
        sx={{
          height: 'var(--router-height)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pb: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            width: '100%',
            gap: 4,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              px: 3,
              flex: 1,
              my: 2,
            }}
          >
            <Grid container spacing={0} alignItems="center" justifyContent="center" sx={{ my: 2 }} >
              <Grid item xs={12}>
                {currentUser.role === 1 ? (
                  <>
                    <Typography variant="h4" sx={{ my: 2, textAlign: 'center' }}>
                      Subscription Details
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    {!currentUser.approved ? (
                      <>
                        <Typography variant="body1" sx={{ color: '#FF0000', mt: 3, pb: 3, textAlign: 'center' }}>
                          Please wait while your account is being approved by an admin. 
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#FF0000', pb: 3, textAlign: 'center' }}>
                          Once approved you can add a subscription and access all the features of Propertee.
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#FF0000', pb: 3, textAlign: 'center' }}>
                          Please ensure you have entered your license number correctly and send the required valid real estate license to <a href="mailto:admin@propertee.org">admin@propertee.org</a>.
                        </Typography>
                      </>
                    ) : (
                      <>
                        {currentUser.subscriptionId ? (
                          <>
                            {currentUser.subscriptionId === 'sub_test_admin_PR0P3RT33' ? (
                              <>
                                <Typography variant="h6" sx={{ color: 'green', my: 1, mb: 2, textAlign: 'center' }}>Admin Subscription Active</Typography>
                                <Typography variant="body1" sx={{ color: 'var(--primary)', my: 1, textAlign: 'center' }}>Unlimited Subscription</Typography>
                              </>
                            ) : (
                              <>
                                <Typography variant="h6" sx={{ color: 'green', my: 1, mb: 2, textAlign: 'center' }}>Subscription Active</Typography>
                                <Typography variant="body1" sx={{ color: 'var(--primary)', my: 1, textAlign: 'center' }}>Monthly Payment Cycle - $99 / Month</Typography>
                                {currentUser.subscriptionEndsAt && (
                                  <Typography variant="body1" sx={{ color: 'var(--primary)', my: 1, textAlign: 'center' }}>
                                    Subscription Cancels On: {new Date(currentUser.subscriptionEndsAt).toLocaleDateString()}
                                  </Typography>
                                )}
                                <Button variant="outlined" onClick={handleEditSubscription} disabled={loading} sx={{ my: 1, width: '100%' }}>
                                  Edit Subscription
                                </Button>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Typography variant="h6" sx={{ color: '#FF0000', my: 3, textAlign: 'center' }}>No Active Subscription</Typography>
                            <Typography variant="body1" sx={{ color: '#FF0000', my: 3, textAlign: 'center' }}>To access all features of the Propertee, please add a valid subscription.</Typography>
                            <Button variant="outlined" onClick={handleSubscribe} disabled={loading} sx={{ my: 1, width: '100%' }}>
                              Subscribe Now
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Typography variant="h4" sx={{ my: 1, textAlign: 'center' }}>
                      Subscription Details
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Typography variant="body1" sx={{ mt: 3, pb: 3, textAlign: 'center' }}>
                      You have a regular Propertee user account. This account allows you to check in and put offers on homes at home opens.
                    </Typography>
                    <Typography variant="body1" sx={{ pb: 3, textAlign: 'center' }}>
                      Stay tuned, our roadmap includes some big features to come for regular users!
                    </Typography>
                  </>
                )}
                {/* <Typography variant="h4" sx={{ my: 2, textAlign: 'center' }}> */}
                {/*   Password Settings */}
                {/* </Typography> */}
                {/* <Divider sx={{ my: 1 }} /> */}
                {passwordIsEditing === false && deleteAccountIsEditing === false && (
                  <Box>
                    <Button variant="containedAccent" onClick={handlePasswordEditClick} disabled={loading} sx={{ my: 1, width: '100%' }}>
                      Change Password
                    </Button>
                    <Button variant="warning" onClick={handleDeleteEditClick} disabled={loading} sx={{ my: 1, width: '100%' }}>
                      Delete Account
                    </Button>
                  </Box>
                )}
                {passwordIsEditing && (
                  <>
                    <TextField
                      fullWidth
                      label="Current Password"
                      name="currentPassword"
                      type="password"
                      value={userDetails.currentPassword}
                      onChange={handleInputChange}
                      margin="dense"
                    />
                    <TextField
                      fullWidth
                      label="New Password"
                      name="newPassword"
                      type="password"
                      value={userDetails.newPassword}
                      onChange={handleInputChange}
                      margin="dense"
                    />
                    <TextField
                      fullWidth
                      label="Confirm Password"
                      name="confirmPassword"
                      type="password"
                      value={userDetails.confirmPassword}
                      onChange={handleInputChange}
                      margin="dense"
                    />
                    <Button variant="outlined" onClick={handlePasswordSubmit} disabled={loading}  sx={{ my: 1, width: '100%' }}>
                      Save Password
                    </Button>
                    <Button variant="warning" onClick={handlePasswordCancel} disabled={loading}  sx={{ my: 1, width: '100%' }}>
                      Cancel
                    </Button>
                  </>
                )}
                {deleteAccountIsEditing && (
                  <>
                    <Typography variant="body1" sx={{ color: '#FF0000', my: 2, textAlign: 'center' }}>
                      Warning: Deleting your account cannot be undone.
                    </Typography>
                    <TextField
                      fullWidth
                      label="Confirm Email"
                      name="confirmEmail"
                      type="email"
                      value={userDetails.confirmEmail}
                      onChange={handleInputChange}
                      margin="dense"
                    />
                    <TextField
                      fullWidth
                      label="Current Password"
                      name="currentPassword"
                      type="password"
                      value={userDetails.currentPassword}
                      onChange={handleInputChange}
                      margin="dense"
                    />
                    <Button variant="outlined" onClick={handleDeleteAccountSubmit} disabled={loading} sx={{ my: 1, width: '100%' }}>
                      Delete Account
                    </Button>
                    <Button variant="warning" onClick={handleCancelDelete} disabled={loading} sx={{ my: 1, width: '100%' }}>
                      Cancel
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>
          <Paper
            elevation={3}
            sx={{
              px: 3,
              flex: 1,
              my: 2,
            }}
          >
            <Grid container spacing={0} alignItems="center" justifyContent="center" sx={{ my: 2 }} >
              <Grid item xs={12}>
                <Typography variant="h4" sx={{ my: 2, textAlign: 'center' }}>
                  User Details
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Box component="form" noValidate autoComplete="off" sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="firstname"
                    value={userDetails.firstname}
                    onChange={handleInputChange}
                    margin="dense"
                    disabled={!isEditing}
                  />
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="lastname"
                    value={userDetails.lastname}
                    onChange={handleInputChange}
                    margin="dense"
                    disabled={!isEditing}
                  />
                  <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    value={userDetails.phone}
                    onChange={handleInputChange}
                    margin="dense"
                    disabled={!isEditing}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+61</InputAdornment>,
                    }}
                  />
                  {isEditing ? (
                    <>
                      {currentUser.role === 1 ? (
                        <Typography variant="body2" sx={{ mt: 1, pb: 1, textAlign: 'center' }}>
                          * For changes to email and license number, please contact the support admin.
                        </Typography>
                      ) : (
                        <Typography variant="body2" sx={{ mt: 1, pb: 1, textAlign: 'center' }}>
                          * For changes to email, please contact the support admin.
                        </Typography>
                      )}
                    </>
                  ) : null}
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={userDetails.email}
                    onChange={handleInputChange}
                    margin="dense"
                    disabled={true}
                  />
                  {currentUser.role === 1 ? (
                    <TextField
                      fullWidth
                      label="License Number"
                      name="licenseNumber"
                      value={userDetails.licenseNumber}
                      onChange={handleInputChange}
                      margin="dense"
                      disabled={true}
                    />
                  ) : null}
                  {isEditing ? (
                    <>
                      <Button variant="outlined" onClick={handleSubmit} disabled={loading} sx={{ my: 1, width: '100%' }}>
                        Save Changes
                      </Button>
                      <Button variant="warning" onClick={handleCancel} disabled={loading} sx={{ my: 1, width: '100%' }}>
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Button variant="containedAccent" onClick={handleEditClick} disabled={loading} sx={{ my: 1, width: '100%' }}>
                      Edit Details
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
      <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
    </div>
  );
};

export default UserPage;
