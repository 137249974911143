
import React from 'react';
import { Box, Typography, Container, Paper, useMediaQuery } from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
//import BackgroundImage from '../assets/background-building.png';
import BackgroundImage from '../assets/HouseImage3.jpg';


function ApproachDescription() {
  const items = [
    [
      { text: 'Manual Sign-in Sheets' },
      { text: 'QR Code Check-In' }
    ],
    [
      { text: 'Buyers manually fill out forms, causing delays.' },
      { text: 'Quick and seamless check-in with a simple scan.' }
    ],
    [
      { text: 'Agents spend extra time organizing data after events.' },
      { text: 'Automatically organized data, saving agents time.' }
    ],
    [
      { text: 'Potential for errors in manually entered data.' },
      { text: 'Accurate, real-time data with reduced error.' }
    ],
    [
      { text: 'Limited insights and transparency in the sales process.' },
      { text: 'Instant insights and transparency for both parties.' }
    ],
  ];

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        height: 'var(--router-height)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: '3px',
        justifyContent: 'center',
        backgroundImage: `
          linear-gradient(to top right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 60%),
          linear-gradient(to top, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 1) 100%),
          url(${BackgroundImage})
        `,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        overflowY: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
    >
      {/* Title Row */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          maxWidth: 'md',
          m: 1,
        }}
      >
        <Box
          sx={{
            backgroundColor: '#000000',
            color: '#ffffff',
            paddingY: 2,
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            width: '50%',
            textAlign: 'center',
          }}
        >
          <Typography variant={isMobile ? "h6" : "h4"} fontWeight="bold">The Old Way</Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: '#5ca0d3',
            color: '#ffffff',
            paddingY: 2,
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            width: '50%',
            textAlign: 'center',
          }}
        >
          <Typography variant={isMobile ? "h6" : "h4"} fontWeight="bold">Modern Check-In</Typography>
        </Box>
      </Box>

      {/* Rows of Items */}
      {items.map((pair, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 1,
            width: '100%',
            maxWidth: '800px',
            flexDirection: isMobile ? 'row' : 'row',
          }}
        >
          {/* Left Side (Old Way) */}
          <Paper
            elevation={3}
            sx={{
              padding: 1,
              width: isMobile ? '90%' : '45%',
              marginRight: isMobile ? 0 : 1,
              marginBottom: isMobile ? 1 : 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              borderRadius: '8px',
              backgroundColor: '#ffffff',
              minHeight: '50px'
            }}
          >
            <Typography variant="body1" sx={{ color: '#000000', fontSize: isMobile ? '0.9rem' : '1.1rem' }}>
              {pair[0].text}
            </Typography>
          </Paper>

          {/* Center Arrow */}
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: isMobile ? 1 : 0 }}>
            <KeyboardDoubleArrowRightIcon sx={{ fontSize: isMobile ? 30 : 70, color: '#5ca0d3' }} />
          </Box>

          {/* Right Side (QR Check-In Way) */}
          <Paper
            elevation={3}
            sx={{
              padding: 1,
              width: isMobile ? '90%' : '45%',
              marginLeft: isMobile ? 0 : 1,
              marginBottom: isMobile ? 1 : 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              borderRadius: '8px',
              backgroundColor: '#ffffff',
              minHeight: '50px'
            }}
          >
            <Typography variant="body1" sx={{ color: '#000000', fontSize: isMobile ? '0.9rem' : '1.1rem' }}>
              {pair[1].text}
            </Typography>
          </Paper>
        </Box>
      ))}
    </Container>
  );
}

export default ApproachDescription;

