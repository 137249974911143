import { collection, doc, addDoc, setDoc, getDoc, getDocs, query, where, deleteDoc, updateDoc, deleteField } from 'firebase/firestore';
import { db } from '../environments/FirebaseConfig';
import dayjs from 'dayjs';

// USER FUNCTIONS
// ---------------------------------------------------------------------------------------------------------------------
export const saveUserData = async (app, uid, data) => {
    await setDoc(doc(db, 'users', uid), data);
}

export const saveGuestSignUpUserData = async (app, uid, data, guestCheckInDocId, homeOpenId) => {
    await setDoc(doc(db, 'users', uid), data);
    await updateDoc(doc(db, `homeOpens/${homeOpenId}/attendanceRegister/${guestCheckInDocId}`), {
        type: 1, //authenticated
        userId: uid,
        email: data.email,
        phoneNumber: data.phoneNumber,
    }); 
}

export const getUserData = async (app, uid) => {
    const userDoc = await getDoc(doc(db, 'users', uid));

    return userDoc.data();
}

export const updateUserData = async (userId, userDetails) => {
    const userRef = doc(db, 'users', userId);

    await updateDoc(userRef, {
        firstname: userDetails.firstname,
        lastname: userDetails.lastname,
        phoneNumber: userDetails.phoneNumber,
    });
}

// APPROVAL FUNCTIONS
// ---------------------------------------------------------------------------------------------------------------------
export const getAgentAccountApprovals = async () => {
    const q = query(collection(db, 'users'), where("role", "==", 1), where("approved", "==", false));
    const querySnapshot = await getDocs(q);
    const approvals = [];
    querySnapshot.forEach((doc) => {
        approvals.push({ id: doc.id, ...doc.data() });
    });
    return approvals;
}

export const approveAgentAccount = async (userId, approverId) => {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
        approved: true,
        approvedBy: approverId,
        timestamp: new Date(),
        rejectReason: deleteField(),
    });
}

export const rejectAgentAccount = async (userId, approverId, rejectReason) => {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
        approved: false,
        approvedBy: approverId,
        timestamp: new Date(),
        rejectReason: rejectReason,
    });
}

// CALENDAR FUNCTIONS
// ---------------------------------------------------------------------------------------------------------------------
export const getCalendarByAgentId = async (agentId) => {
    const eventsRef = collection(db, `calendar/${agentId}/events`);
    const querySnapshot = await getDocs(eventsRef);

    const events = [];

    for (const event of querySnapshot.docs) {
        if (event.data().type === 1) {
            const { start, end, title } = event.data();
            events.push({
                start: start,
                end: end,
                title: title,
                resources: {
                    id: event.id,
                    type: event.data().type,
                    propertyId: event.data().propertyId,
                    event: event.data(),
                },
            });
        }
        else {
            const { start, end, title } = event.data();
            events.push({
                start: start,
                end: end,
                title: title,
                resources: {
                    id: event.id,
                    type: event.data().type,
                },
            });
        }
    }

    return events;
}

export const getHomeOpensByAgentId = async (agentId) => {
    const homeOpensRef = collection(db, 'homeOpens');
    
    const homeOpensQuery = query(homeOpensRef, where('agentId', '==', agentId));
    const homeOpensSnapshot = await getDocs(homeOpensQuery);

    const events = [];

    for (const event of homeOpensSnapshot.docs) {
        const { start, end } = event.data();
        events.push({
            start: start,
            end: end,
            title: event.data().address,
            resources: {
                id: event.id,
                type: 2,
                propertyId: event.data().propertyId,
                event: event.data(),
            },
        });
    }

    return events;
}

export const getAttendanceRegisterByHomeOpenId = async (homeOpenId) => {
    const attendanceRegisterRef = collection(db, `homeOpens/${homeOpenId}/attendanceRegister`);
    const attendanceSnapshot = await getDocs(attendanceRegisterRef);

    const attendanceRegister = [];

    for (const attendance of attendanceSnapshot.docs) {
        attendanceRegister.push({
            id: attendance.id,
            ...attendance.data(),
        });
    }

    return attendanceRegister;
}

export const getSingleHomeOpenById = async (homeOpenId) => {
    const docRef = doc(db, 'homeOpens', homeOpenId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        console.log("No such document!");
    }
}

export const createEvent = async (agentId, title, start, end, type) => {
    const eventRef = collection(db, `calendar/${agentId}/events`);

    await setDoc(doc(eventRef), {
        title: title,
        start: new Date(dayjs(start).toISOString()),
        end: new Date(dayjs(end).toISOString()),
        type: type,
    });
}

export const createInspection = async (agentId, start, end, propertyId, address) => {
    const eventRef = collection(db, `calendar/${agentId}/events`);

    await setDoc(doc(eventRef), {
        type: 1,
        title: `${address}`,
        address: address,
        start: new Date(dayjs(start).toISOString()),
        end: new Date(dayjs(end).toISOString()),
        propertyId: propertyId,
    });
}

export const updateEvent = async (agentId, eventId, title, start, end) => {
    const eventRef = doc(db, `calendar/${agentId}/events`, eventId);

    await updateDoc(eventRef, {
        title: title,
        start: new Date(dayjs(start).toISOString()),
        end: new Date(dayjs(end).toISOString()),
    });
}

export const updateInspection = async (agentId, eventId, start, end) => {
    const eventRef = doc(db, `calendar/${agentId}/events`, eventId);

    await updateDoc(eventRef, {
        start: new Date(dayjs(start).toISOString()),
        end: new Date(dayjs(end).toISOString()),
    });
}

export const deleteEvent = async (agentId, eventId) => {
    const eventRef = doc(db, `calendar/${agentId}/events`, eventId);

    await deleteDoc(eventRef);
}

export const createHomeOpen = async (agentId, start, end, propertyId, address) => {
    const messagesRef = collection(db, 'homeOpens');

    await setDoc(doc(messagesRef), {
        agentId: agentId,
        address: address,
        start: new Date(dayjs(start).toISOString()),
        end: new Date(dayjs(end).toISOString()),
        propertyId: propertyId,
    });
}

export const updateHomeOpen = async (eventId, start, end,) => {
    const eventRef = doc(db, `homeOpens`, eventId);

    await updateDoc(eventRef, {
        start: new Date(dayjs(start).toISOString()),
        end: new Date(dayjs(end).toISOString()),
    });
}

export const deleteHomeOpen = async (eventId) => {
    const eventRef = doc(db, `homeOpens`, eventId);

    await deleteDoc(eventRef);
}

// SEARCH & PROPERTY FUNCTIONS
// ---------------------------------------------------------------------------------------------------------------------
export const getPropertiesByAgentId = async (agentId) => {
    const propertiesRef = collection(db, 'properties');
    const q = query(propertiesRef, where("agentId", "==", agentId));
    const querySnapshot = await getDocs(q);
    const properties = [];
    querySnapshot.forEach((doc) => {
        properties.push({ id: doc.id, ...doc.data() });
    });
    return properties;
}

/** getSingleListinById:
 *                      - priceParameters: '=' = the price listed, '>' = the price is greater than, '*' = open to offers.
 *                      - listingType: '-1' = off market, '0' = casually listed, '1' = for sale.
 **/
export const getSinglePropertyById = async (listingId) => {
    const docRef = doc(db, 'properties', listingId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        console.log("No such document!");
    }
}

/** CreateProperty:
 *                      - priceParameters: '=' = the price listed, '>' = the price is greater than, '*' = open to offers.
 *                      - listingType: '-1' = off market, '0' = casually listed, '1' = for sale.
 **/
export const createProperty = async (agentId, propertyData) => {
    const listingsRef = collection(db, 'properties');
    const newPropertyRef = doc(listingsRef);
    const propertyId = newPropertyRef.id;

    const propertyDataWithoutImages = { ...propertyData };
    delete propertyDataWithoutImages.images;

    await setDoc(newPropertyRef, {
        agentId: agentId,
        price: propertyDataWithoutImages.price,
        priceParameter: propertyDataWithoutImages.priceParameter,
        address: propertyDataWithoutImages.address,
        listingType: propertyDataWithoutImages.listingType,
        bedrooms: propertyDataWithoutImages.bedrooms,
        bathrooms: propertyDataWithoutImages.bathrooms,
        parking: propertyDataWithoutImages.parking,
        title: propertyDataWithoutImages.title,
        shortDescription: propertyDataWithoutImages.shortDescription,
        description: propertyDataWithoutImages.description,
        imgUrl: '', 
        images: [], 
    });

    return propertyId;
}

/** UpdateProperty:
 *                      - priceParameters: '=' = the price listed, '>' = the price is greater than, '*' = open to offers.
 *                      - listingType: '-1' = off market, '0' = casually listed, '1' = for sale.
 **/
export const updateProperty = async (propertyId, propertyData) => {
    const propertyRef = doc(db, 'properties', propertyId);
    console.log(propertyData.images);

    await updateDoc(propertyRef, {
        price: propertyData.price,
        priceParameter: propertyData.priceParameter, 
        listingType: propertyData.listingType,
        bedrooms: propertyData.bedrooms,
        bathrooms: propertyData.bathrooms,
        parking: propertyData.parking,
        title: propertyData.title,
        shortDescription: propertyData.shortDescription,
        description: propertyData.description,
        imgUrl: propertyData.images[0],
        images: propertyData.images,
    });
}

export const updatePropertyImages = async (propertyId, imageUrls) => {
    const propertyRef = doc(db, 'properties', propertyId);
    await updateDoc(propertyRef, {
      imgUrl: imageUrls[0] || '',
      images: imageUrls,
    });
};
  
export const deleteProperty = async (propertyId) => {
    const propertyRef = doc(db, 'properties', propertyId);
    await deleteDoc(propertyRef);
};

// CONTACT US FUNCTIONS
// ---------------------------------------------------------------------------------------------------------------------
export const createContactUsMessage = async (messageData) => {
    const messagesRef = collection(db, 'contactUs');

    await setDoc(doc(messagesRef), {
        type: messageData.type,
        name: messageData.name,
        email: messageData.email,
        phone: messageData.phone,
        message: messageData.message,
    });
}

// CHECK-IN FUNCTIONS
// ---------------------------------------------------------------------------------------------------------------------
export const checkInToHomeOpenGuest = async (homeOpenId, guestDetails) => {
    const homeOpenRef = collection(db, `homeOpens/${homeOpenId}/attendanceRegister`);

    const normalizedEmail = guestDetails.email.trim().toLowerCase();

    const q = query(
        homeOpenRef, 
        where("email", "==", normalizedEmail),
        where("phoneNumber", "==", guestDetails.phoneNumber),
    );

    try {
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const existingDocRef = querySnapshot.docs[0].ref;
            await updateDoc(existingDocRef, {
                offer: guestDetails.offer ? parseFloat(guestDetails.offer) : null,
                financeType: guestDetails.financeType,
                termite: guestDetails.termite,
                settlement: guestDetails.settlement,
                otherConditions: guestDetails.otherConditions,
                timestamp: new Date(),
            });

            return existingDocRef.id;
        } else {
            // Create a new doc if not found
            const docRef = await addDoc(homeOpenRef, {
                type: 0, // Guest
                name: guestDetails.name,
                email: normalizedEmail,
                phoneNumber: guestDetails.phoneNumber,
                offer: guestDetails.offer ? parseFloat(guestDetails.offer) : null,
                timestamp: new Date(),
                financeType: guestDetails.financeType,
                termite: guestDetails.termite,
                settlement: guestDetails.settlement,
                otherConditions: guestDetails.otherConditions,
            });

            return docRef.id;
        }
    } catch (error) {
        console.error("Error checking in guest:", error);
        throw new Error("Failed to check in guest. Please try again.");
    }
}

export const checkInToHomeOpenAuthenticated = async (homeOpenId, userDetails) => {
    const homeOpenRef = collection(db, `homeOpens/${homeOpenId}/attendanceRegister`);

    await setDoc(doc(homeOpenRef), {
        type: 1, //authenticated
        userId: userDetails.userId,
        name: userDetails.name,
        email: userDetails.email,
        phoneNumber: userDetails.phoneNumber,
        offer: userDetails.offer ? parseFloat(userDetails.offer) : null,
        timestamp: new Date(),    
    });
}

export const checkHomeOpenRegisterForUserById = async (homeOpenId, userId) => {
    const homeOpenRef = collection(db, `homeOpens/${homeOpenId}/attendanceRegister`);
    const q = query(homeOpenRef, where("userId", "==", userId));
    const querySnapshot = await getDocs(q);
    const users = [];
    querySnapshot.forEach((doc) => {
        users.push({ id: doc.id, ...doc.data() });
    });

    if(users.length > 0) {
        return true;
    }
    else {
        return false;
    }
}

export const submitOfferAuthenticated = async (homeOpenId, userId, offer, additionalOfferDetails) => {
    const homeOpenRef = collection(db, `homeOpens/${homeOpenId}/attendanceRegister`);
    const q = query(homeOpenRef, where("userId", "==", userId));
    const querySnapshot = await getDocs(q);
    const users = [];
    querySnapshot.forEach((doc) => {
        users.push({ id: doc.id, ...doc.data() });
    });

    if(users.length > 0) {
        const userDoc = doc(db, `homeOpens/${homeOpenId}/attendanceRegister`, users[0].id);
        await updateDoc(userDoc, {
            offer: parseFloat(offer),
            ...additionalOfferDetails,
        });
    }
}

// PAYMENT FUNCTIONS
// ---------------------------------------------------------------------------------------------------------------------
export const getProducts = async () => {
    const productsRef = collection(db, 'products');
    const q = query(productsRef, where("active", "==", true));
    const querySnapshot = await getDocs(q);

    const products = [];

    querySnapshot.forEach((doc) => {
        products.push({ id: doc.id, ...doc.data() });
    });
    
    //for each product return the price subcollection data as well
    for (const product of products) {
        const pricesRef = collection(db, `products/${product.id}/prices`);
        const pricesSnapshot = await getDocs(pricesRef);

        const prices = [];

        pricesSnapshot.forEach((doc) => {
            prices.push({ id: doc.id, ...doc.data() });
        });

        product.prices = prices;
    }

    return products;
}
