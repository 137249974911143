import React from 'react';
import { List, ListItemIcon, ListItemText, Divider, Typography, ListItemButton, useTheme } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ContactsIcon from '@mui/icons-material/Contacts';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import EventIcon from '@mui/icons-material/Event';
import HouseIcon from '@mui/icons-material/House';

function DrawerList({ handleNavigation, handleLogout, isAgent, isAdmin, currentUser }) {
  const theme = useTheme();

  return (
    <div style={{ width: 250 }}>
      <List>
        <ListItemButton onClick={() => handleNavigation('/user')}>
          <ListItemIcon>
            <AccountCircleIcon style={{ fontSize: 40, color: theme.palette.primary.main }} />
          </ListItemIcon>
          <ListItemText
            primary={
              currentUser ? (
                <Typography variant="h6" color="primary">
                  {currentUser.firstname} {currentUser.lastname}
                </Typography>
              ) : (
                <Typography variant="h6" color="primary">
                  N/A
                </Typography>
              )
            }
          />
        </ListItemButton>
        <Divider />
        <ListItemButton onClick={() => handleNavigation('/')}>
          <ListItemIcon><HomeIcon color="primary" /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
        {isAdmin && (
          <ListItemButton onClick={() => handleNavigation('/agent-verification-approval')}>
            <ListItemIcon><EventIcon color="primary" /></ListItemIcon>
            <ListItemText primary="Approvals" />
          </ListItemButton>
        )}
        {isAgent && (
          <>
            <ListItemButton onClick={() => handleNavigation('/events')}>
              <ListItemIcon><EventIcon color="primary" /></ListItemIcon>
              <ListItemText primary="My Events" />
            </ListItemButton>
            <ListItemButton onClick={() => handleNavigation('/search')}>
              <ListItemIcon><HouseIcon color="primary" /></ListItemIcon>
              <ListItemText primary="My Homes" />
            </ListItemButton>
          </>
        )}
        <ListItemButton onClick={() => handleNavigation('/about')}>
          <ListItemIcon><InfoIcon color="primary" /></ListItemIcon>
          <ListItemText primary="About" />
        </ListItemButton>
        <ListItemButton onClick={() => handleNavigation('/contact')}>
          <ListItemIcon><ContactsIcon color="primary" /></ListItemIcon>
          <ListItemText primary="Contact" />
        </ListItemButton>
      </List>
      <Divider />
      <List>
        <ListItemButton onClick={handleLogout}>
          <ListItemIcon><ExitToAppIcon color="primary" /></ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </div>
  );
}

export default DrawerList;
