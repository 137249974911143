import React, { useState, useEffect } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import TextField from '@mui/material/TextField';
import { Autocomplete as MuiAutocomplete } from '@mui/material';
import debounce from 'lodash/debounce';
import { maps } from '../environments/FirebaseConfig';

const libraries = ['places'];

function PlacesAutocomplete({ setPropertyData, closeAutocomplete }) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: maps,
    libraries,
    loadingElement: <div>Loading...</div>, // Optional: Provide a loading element
    onLoad: () => console.log("Google Maps API script loaded asynchronously"),
  });

  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [autocompleteService, setAutocompleteService] = useState(null);

  useEffect(() => {
    if (isLoaded && !autocompleteService) {
      const service = new window.google.maps.places.AutocompleteService();
      setAutocompleteService(service);
    }
  }, [isLoaded, autocompleteService]);

  const handleSearch = debounce((value) => {
    if (!value || !autocompleteService) {
      setOptions([]);
      return;
    }

    autocompleteService.getPlacePredictions({
      input: value,
      types: ['address'], // Restrict search to addresses
      componentRestrictions: { country: 'au' }
    }, (predictions, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
        setOptions(predictions.map(prediction => ({
          description: prediction.description,
          place_id: prediction.place_id
        })));
      }
    });
  }, 500); // 500 ms delay

  const handleChange = (event) => {
    const value = event.target.value || '';
    setInputValue(value);
    handleSearch(value);
    if (!value) {
      closeAutocomplete();
    }
  };

  const handleSelect = (event, value) => {
    if (!value) {
      closeAutocomplete();
      return;
    }

    const selectedOption = options.find(option => option.description === value);
    if (!selectedOption) {
      closeAutocomplete();
      return;
    }

    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    service.getDetails({
      placeId: selectedOption.place_id,
      fields: ['formatted_address']
    }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
        const formattedAddress = place.formatted_address;

        setPropertyData(prevData => ({ ...prevData, address: formattedAddress || '' }));
        setInputValue(formattedAddress || '');
        closeAutocomplete();
      }
    });
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <MuiAutocomplete
      freeSolo
      options={options.map(option => option.description)}
      value={inputValue}
      onChange={handleSelect}
      onInputChange={(_, value) => handleChange({ target: { value } })}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search location"
          margin="normal"
          variant="outlined"
          fullWidth
          onChange={handleChange}
        />
      )}
    />
  );
}

export default PlacesAutocomplete;
