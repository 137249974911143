
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

function Footer() {
  const theme = useTheme();

  return (
    <footer style={{
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.background,
      textAlign: 'center',
      height: 'var(--footer-height)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <Container>
        <Typography variant="body1">
          © {new Date().getFullYear()} Propertee. All rights reserved.
        </Typography>
      </Container>
    </footer>
  );
}

export default Footer;
