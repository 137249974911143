
import React from 'react';
import Contact from '../components/Contact';
import { createContactUsMessage } from '../utilities/FirestoreFunctions';

const ContactPage = () => {
  const handleFormSubmit = async (formData) => {
    await createContactUsMessage(formData);
  };

  return (
    <Contact onSubmit={handleFormSubmit} />
  );
};

export default ContactPage;
