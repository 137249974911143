import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { getAgentAccountApprovals, approveAgentAccount, rejectAgentAccount } from '../utilities/FirestoreFunctions';
import AuthContext from '../utilities/AuthContext';

import ProperteeCircularProgress from '../components/ProperteeCircular.js';

const AgentVerificationApproval = () => {
  const [isCheckboxChecked, setCheckboxChecked] = useState(false);
  const [isTextCollapsed, setTextCollapsed] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const { currentUser } = useContext(AuthContext);

  const [denyReason, setDenyReason] = useState('');

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const fetchedUsers = await getAgentAccountApprovals();
        setUsers(fetchedUsers);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching agent approvals:', err);
        setError(err);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleCheckboxChange = (event) => {
    setCheckboxChecked(event.target.checked);
    setTextCollapsed(event.target.checked);
  };

  const handleRowClick = (id) => {
    if (!isCheckboxChecked) return;
    const newExpandedRows = expandedRows.includes(id)
      ? expandedRows.filter((rowId) => rowId !== id)
      : [...expandedRows, id];
    setExpandedRows(newExpandedRows);
  };

  const handleActionButtonClick = (e, action, user) => {
    e.stopPropagation();
    setSelectedAction(action);
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const isRowExpanded = (id) => expandedRows.includes(id);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setDenyReason('');
  };

  const handleConfirmAction = async () => {
    console.log(`Action: ${selectedAction} for agent: ${selectedUser.firstname} ${selectedUser.lastname}`);
    try {
      if (selectedAction === 'accept') {
        await approveAgentAccount(selectedUser.id, currentUser.uid);
        setUsers(users.filter((user) => user.id !== selectedUser.id));
      } else {
        await rejectAgentAccount(selectedUser.id, currentUser.uid, denyReason);

        setUsers(users.map((user) =>
          user.id === selectedUser.id ? { ...user, rejectReason: denyReason } : user
        ));
      }

      setExpandedRows(expandedRows.filter((id) => id !== selectedUser.id));
      setOpenDialog(false);
      setDenyReason('');
    } catch (error) {
      console.error(`Error processing agent approval: ${error}`);
    }
  };

  const tableStyle = isCheckboxChecked ? {} : { opacity: 0.5, pointerEvents: 'none' };

  return (
    <Box
      sx={{
        height: 'calc(var(--router-height))',
        paddingRight: '10px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ padding: '20px', paddingTop: '0px' }}>
        <Typography variant="h5" gutterBottom>
          Agent Approvals Management
        </Typography>
        <Collapse in={!isTextCollapsed}>
          <Typography variant="body1" gutterBottom>
            As a Propertee Admin, it is your responsibility to:
            <ol>
              <li>Verify the agent's ID to ensure the legitimacy of their identity.</li>
              <li>Confirm an agent has sent you their real estate license.</li>
              <li>
                Verify an agent's license number is valid in the relevant check portals and that their
                identity matches with the license.
              </li>
            </ol>
            Please accept the terms above to proceed with the approvals process.
          </Typography>
        </Collapse>
        <FormControlLabel
          control={
            <Checkbox
              checked={isCheckboxChecked}
              onChange={handleCheckboxChange}
              icon={<CheckCircleOutlineIcon style={{ fontSize: 30 }} />}
              checkedIcon={<CheckCircleOutlineIcon style={{ fontSize: 30 }} />}
              color="primary"
            />
          }
          label={isCheckboxChecked ? 'Thank you for accepting the terms.' : 'Accept Above Terms'}
          labelPlacement="end"
          style={{ marginTop: '5px' }}
        />

        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <ProperteeCircularProgress />
          </div>
        ) : error ? (
          <Typography variant="body1" style={{ marginTop: '20px', color: '#FF0000' }}>
            Error loading agents: {error.message}
          </Typography>
        ) : (
          <TableContainer component={Paper} style={{ marginTop: '10px', ...tableStyle }}>
            <Table aria-label="approvals table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      textAlign: 'center',
                      width: '20px',
                      borderRight: '1px solid rgba(224, 224, 224, 1)',
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>License Number</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user, index) => (
                  <React.Fragment key={user.id}>
                    <TableRow
                      onClick={() => handleRowClick(user.id)}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: index % 2 === 0 ? 'rgba(173, 216, 230, 0.2)' : '',
                      }}
                    >
                      <TableCell
                        style={{
                          textAlign: 'center',
                          width: '20px',
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell>
                        {user.firstname + ' ' + user.lastname}
                        <Collapse in={isRowExpanded(user.id)} timeout="auto" unmountOnExit>
                          <Typography variant="body2" style={{ marginTop: '10px' }}>
                            {user.licenseNumber}
                          </Typography>
                        </Collapse>
                      </TableCell>
                      <TableCell>{user.licenseNumber}</TableCell>
                      <TableCell>{user.rejectReason ? "Denied: " + user.rejectReason : "Awaiting approval"}</TableCell>
                      <TableCell align="right">
                        <Button
                          variant="containedAccent"
                          style={{
                            margin: 1,
                            width: '100px',
                            opacity: isRowExpanded(user.id) ? 1 : 0.5,
                          }}
                          onClick={(e) => handleActionButtonClick(e, 'accept', user)}
                          disabled={!isRowExpanded(user.id)}
                        >
                          Accept
                        </Button>
                        <Button
                          variant="warning"
                          style={{
                            margin: 1,
                            color: '#ffffff',
                            width: '100px',
                            opacity: isRowExpanded(user.id) ? 1 : 0.5
                          }}
                          onClick={(e) => handleActionButtonClick(e, 'deny', user)}
                          disabled={!isRowExpanded(user.id)}
                        >
                          Deny
                        </Button>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>Confirm Action</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to {selectedAction} the account for{' '}
              {selectedUser?.firstname} {selectedUser?.lastname}?
            </DialogContentText>
            {selectedAction === 'deny' && (
              <TextField
                autoFocus
                margin="dense"
                label="Reason for Denial"
                type="text"
                fullWidth
                value={denyReason}
                onChange={(e) => setDenyReason(e.target.value)}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleConfirmAction}
              color="primary"
              autoFocus
              disabled={selectedAction === 'deny' && denyReason.trim() === ''}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Box>
  );
};

export default AgentVerificationApproval;
