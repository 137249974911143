import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Typography, Button, Container, Box, Paper } from '@mui/material';
import { getProducts } from '../utilities/FirestoreFunctions';
import { handlePayment } from '../utilities/StripeProvider';
import AuthContext from '../utilities/AuthContext';

import ProperteeCircularProgress from '../components/ProperteeCircular.js';

const PaymentsPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleClick = async (product) => {
    setLoading(true);
    await handlePayment(product, currentUser.email, currentUser.firstname, currentUser.lastname);
  }

  const formatPrice = (amount, currency) => {
    return (amount / 100).toLocaleString('en-AU', {
      style: 'currency',
      currency: currency,
    });
  };

  useEffect(() => {
    if (currentUser &&
      currentUser.subscriptionId &&
      currentUser.subscriptionId !== null &&
      currentUser.subscriptionId !== ''
    ) {
      navigate('/user'); // Redirect to the user page
    } else {
      const fetchProducts = async () => {
        const products = await getProducts();
        setProducts(products);
        setLoading(false);
      };
      fetchProducts();
    }
  }, [currentUser, navigate]);

  if (loading) {
    return (
      <Container
      // maxWidth="md"
      // sx={{
      //   height: 'var(--router-height)',
      //   display: 'flex',
      //   alignItems: 'flex-start',
      //   justifyContent: 'flex-start',
      //   flexDirection: 'column',
      //   pb: 4,
      // }}
      >
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'var(--router-height)' }}>
          <ProperteeCircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container
      maxWidth="md"
      sx={{
        height: 'var(--router-height)',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        pb: 4,
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            mt: 2,
            width: '100%',
          }}
        >
          <Typography variant="h4" gutterBottom>Payments Page</Typography>
          <Divider sx={{ mb: 2 }} />
          {products.map((product) => (
            <div key={product.id}>
              <Typography variant="h5">{product.name}</Typography>
              <Typography variant="body1">{product.description}</Typography>
              <Typography variant="body1">{formatPrice(product.prices[0].unit_amount, product.prices[0].currency)}</Typography>
              <Button fullWidth variant="contained" color="accent" onClick={() => handleClick(product)} sx={{ mt: 2 }}>
                Subscribe
              </Button>
            </div>
          ))}
        </Paper>
      </Box>
    </Container>
  );
};

export default PaymentsPage;
