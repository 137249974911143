
import React, { useState } from 'react';
import { CardMedia, IconButton, Modal } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';

function ImageSliderSimple({ images }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [openFullscreen, setOpenFullscreen] = useState(false);

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handleImageClick = () => {
    setOpenFullscreen(true);
  };

  const handleCloseFullscreen = () => {
    setOpenFullscreen(false);
  };

  return (
    <>
      <div
        className="home-image-container-simple"
        style={{ position: 'relative', display: 'inline-block' }}
      >
        {images.length > 1 && (
          <IconButton
            onClick={handlePreviousImage}
            className="carousel-arrow carousel-arrow-left"
            style={{
              position: 'absolute',
              top: '50%',
              left: '10px',
              transform: 'translateY(-50%)',
              color: '#ffffff',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <CardMedia
          component="img"
          alt="House"
          image={images[currentImageIndex]}
          title="House"
          style={{
            borderRadius: '10px',
            cursor: 'pointer',
            aspectRatio: '16/9',
            objectFit: 'cover',
          }}
          className="home-image"
          onClick={handleImageClick}
        />
        {images.length > 1 && (
          <IconButton
            onClick={handleNextImage}
            className="carousel-arrow carousel-arrow-right"
            style={{
              position: 'absolute',
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
              color: '#ffffff',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
          >
            <ArrowForwardIcon />
          </IconButton>
        )}
      </div>

      {/* Fullscreen Modal */}
      <Modal
        open={openFullscreen}
        onClose={handleCloseFullscreen}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent background
          },
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
          }}
        >
          {/* Close Button */}
          <IconButton
            onClick={handleCloseFullscreen}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              color: '#ffffff',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Previous Arrow in Fullscreen */}
          {images.length > 1 && (
            <IconButton
              onClick={handlePreviousImage}
              style={{
                position: 'absolute',
                top: '50%',
                left: '20px',
                transform: 'translateY(-50%)',
                color: '#ffffff',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
            >
              <ArrowBackIcon fontSize="large" />
            </IconButton>
          )}

          {/* Next Arrow in Fullscreen */}
          {images.length > 1 && (
            <IconButton
              onClick={handleNextImage}
              style={{
                position: 'absolute',
                top: '50%',
                right: '20px',
                transform: 'translateY(-50%)',
                color: '#ffffff',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
            >
              <ArrowForwardIcon fontSize="large" />
            </IconButton>
          )}

          {/* Fullscreen Image */}
          <img
            src={images[currentImageIndex]}
            alt="House"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </div>
      </Modal>
    </>
  );
}

export default ImageSliderSimple;

