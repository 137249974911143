
import React, { useState, useContext, useEffect } from 'react';
import { Box, Select, Button, CircularProgress, Paper, Container, Divider, InputLabel, Typography, TextField, FormControl, FormHelperText, MenuItem } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { createHomeOpen, createEvent, createInspection, getPropertiesByAgentId } from '../utilities/FirestoreFunctions';
import AuthContext from '../utilities/AuthContext';


const NewEventForm = ({ st, et, setValue, onNewEventCreated }) => {
  const { currentUser } = useContext(AuthContext);

  const [properties, setProperties] = useState([]);
  const [propertiesFetched, setPropertiesFetched] = useState(false);

  const [formData, setFormData] = useState({
    eventType: 0,
    title: '',
    start: st ? dayjs(st) : null,
    end: et ? dayjs(et) : null,
    propertyId: '',
    address: '',
  });

  const [errors, setErrors] = useState({
    start: false,
    end: false,
  });

  const [formError, setFormError] = useState('');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const fetchedProperties = await getPropertiesByAgentId(currentUser.uid);
        setProperties(fetchedProperties);
        setPropertiesFetched(true);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };

    if ((formData.eventType === 1 || formData.eventType === 2) && !propertiesFetched) {
      fetchProperties();
    }
  }, [currentUser.uid, formData.eventType, propertiesFetched]);

  const validateTitle = (title) => {
    if (title === '') {
      return false;
    }

    return true;
  };

  const validateStartDate = (date) => {
    if (date === null) {
      return false;
    }

    if (new Date(date.toISOString()) < new Date()) {
      return false;
    }

    return true;
  };

  const validateEndDate = (date) => {
    if (date === null) {
      return false;
    }

    if (new Date(date.toISOString()) <= new Date(formData.start.toISOString())) {
      return false;
    }

    return true;
  };

  const validateProperty = (propertyId) => {
    if (propertyId === '' || propertyId === 0) {
      return false;
    }

    return true;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Clear errors as the user types
    setErrors({
      ...errors,
      [name]: false
    });

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleChange = (event) => {
    // Clear errors as the user types
    setErrors({
      ...errors,
      eventType: false
    });

    setFormData({
      ...formData,
      eventType: event.target.value
    });
  };

  const handlePropertyChange = (event) => {
    // Clear errors as the user types
    setErrors({
      ...errors,
      propertyId: false
    });

    const property = properties.find(property => property.id === event.target.value);

    setFormData({
      ...formData,
      propertyId: event.target.value,
      address: property.address,
    });
  };

  const handleStartPickerChange = (value) => {
    // Clear errors as the user types
    setErrors({
      ...errors,
      start: false
    });

    setFormData(prevFormData => ({
      ...prevFormData,
      start: value ? value : null
    }));
  };

  const handleEndPickerChange = (value) => {
    // Clear errors as the user types
    setErrors({
      ...errors,
      end: false
    });

    setFormData(prevFormData => ({
      ...prevFormData,
      end: value ? value : null
    }));
  };

  const handleClick = () => {
    setValue(0);
  }

  const handleSaveClick = async () => {
    let valid = true;

    if ((formData.eventType === 0 || formData.eventType === 3 || formData.eventType === 4) && !validateTitle(formData.title)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: true
      }));
      valid = false;
    }

    if (!validateStartDate(formData.start)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        start: true
      }));
      valid = false;
    }

    if (!validateEndDate(formData.end)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        end: true
      }));
      valid = false;
    }

    if ((formData.eventType === 1 || formData.eventType === 2) && !validateProperty(formData.propertyId)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        propertyId: true
      }));
      valid = false;
    }

    if (valid) {
      setLoading(true);
      try {
        if (formData.eventType === 0) {
          await createEvent(currentUser.uid, formData.title, formData.start, formData.end, 0);
          console.log('Successfully created a meeting.');
          onNewEventCreated(); // Trigger event re-fetch
        } else if (formData.eventType === 1) {
          await createInspection(currentUser.uid, formData.start, formData.end, formData.propertyId, formData.address);
          console.log('Successfully created an inspection.');
          onNewEventCreated(); // Trigger event re-fetch
        } else if (formData.eventType === 2) {
          await createHomeOpen(currentUser.uid, formData.start, formData.end, formData.propertyId, formData.address);
          console.log('Successfully created an home open.');
          onNewEventCreated(); // Trigger event re-fetch
        } else if (formData.eventType === 3) {
          await createEvent(currentUser.uid, formData.title, formData.start, formData.end, 3);
          console.log('Successfully created a task.');
          onNewEventCreated(); // Trigger event re-fetch   
        } else {
          await createEvent(currentUser.uid, formData.title, formData.start, formData.end, 4);
          console.log('Successfully created an event.');
          onNewEventCreated(); // Trigger event re-fetch
        }
        setFormError('');
      } catch (error) {
        console.error('Error saving event: ', error);
        setFormError("An unexpected error occurred. Please try again later.");
      }
      setLoading(false);
      setValue(0);
    }
  }

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ my: 2, p: 3 }}>
        <Box width="100%" mb={2}>
          <Typography variant="h4" align="center" sx={{ color: '#000000' }}>
            Create New Event
          </Typography>
          <Divider sx={{ mt: 1, width: '100%' }} />
        </Box>
        <Box component="form" noValidate sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <FormControl variant="outlined" margin="dense" sx={{ width: '100%' }}>
            <InputLabel>Event Type</InputLabel>
            <Select
              value={formData.eventType}
              label="Event Type"
              onChange={handleChange}
            >
              <MenuItem value={0}>Meeting</MenuItem>
              <MenuItem value={1}>Inspection</MenuItem>
              <MenuItem value={2}>Home Open</MenuItem>
              <MenuItem value={3}>Task</MenuItem>
              <MenuItem value={4}>Other</MenuItem>
            </Select>
          </FormControl>

          {(formData.eventType === 0 || formData.eventType === 3 || formData.eventType === 4) && (
            <FormControl variant="outlined" margin="dense" sx={{ width: '100%' }}>
              <TextField
                label="Title"
                name="title"
                variant="outlined"
                value={formData.title}
                onChange={handleInputChange}
                inputProps={{ style: { backgroundColor: '#ffffff' } }}
              />
              {errors.title && <FormHelperText>Title must not be empty</FormHelperText>}
            </FormControl>
          )}
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}>
            <FormControl error={errors.start} variant="outlined" margin="dense" sx={{ width: '49%' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Start Time"
                  name="start"
                  variant="outlined"
                  value={formData.start}
                  onChange={handleStartPickerChange}
                  format="DD/MM/YYYY HH:mm"
                  sx={{ backgroundColor: 'background.datepicker' }}
                />
                {errors.start && <FormHelperText>Date must be valid, i.e. in the future</FormHelperText>}
              </LocalizationProvider>
            </FormControl>

            <FormControl error={errors.end} variant="outlined" margin="dense" sx={{ width: '49%' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="End Time"
                  name="end"
                  variant="outlined"
                  value={formData.end}
                  onChange={handleEndPickerChange}
                  format="DD/MM/YYYY HH:mm"
                  sx={{ backgroundColor: 'background.datepicker' }}
                />
                {errors.end && <FormHelperText>Date and time must be after the start time.</FormHelperText>}
              </LocalizationProvider>
            </FormControl>
          </Box>
          {(formData.eventType === 1 || formData.eventType === 2) && (
            <FormControl variant="outlined" margin="dense" sx={{ width: '100%' }}>
              <InputLabel>Property</InputLabel>
              <Select
                label="Property"
                name="propertyId"
                value={formData.propertyId}
                onChange={handlePropertyChange}
              >
                {properties.length > 0 ? (
                  properties.map((property) => (
                    <MenuItem key={property.id} value={property.id}>
                      {`${property.address}`}
                    </MenuItem>
                  ))) : (
                  <MenuItem disabled value={0}>No properties found</MenuItem>
                )}
              </Select>
              {errors.propertyId && <FormHelperText>A property must be selected</FormHelperText>}
            </FormControl>
          )}
          <Button
            variant="containedAccent"
            onClick={handleSaveClick}
            sx={{
              width: '100%',
              my: 1
            }}
          >
            {loading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
          <Button
            onClick={handleClick}
            variant="outlined"
            sx={{
              width: '100%',
            }}
          >
            Cancel
          </Button>
          {formError && <Typography color="error" sx={{ mt: 1 }}>{formError}</Typography>}
        </Box>
      </Paper>
    </Container>
  );
};

export default NewEventForm;
