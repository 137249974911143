//Take from contact form page
import React, { useState } from 'react';
import { Grid, Paper, Button, Typography, TextField, Box, Select, Container, InputLabel, FormControl, MenuItem } from '@mui/material';
import { createContactUsMessage } from '../utilities/FirestoreFunctions';

import ProperteeCircularProgress from '../components/ProperteeCircular.js';

function HomeContact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    type: 0,
    message: '',
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    type: false,
    message: false,
  });

  const [formError, setFormError] = useState('');

  const [loading, setLoading] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Clear errors as the user types
    setErrors({
      ...errors,
      [name]: false
    });

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      type: event.target.value
    });
  };

  const validateName = (name) => {
    // Basic name validation
    return name.length > 0;
  }

  const validateEmail = (email) => {
    // Basic email validation using regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    // Basic phone validation using regex
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  }

  const validateMessage = (message) => {
    // Basic message validation
    return message.length > 0;
  }

  const handleSaveClick = async () => {
    let valid = true;

    if (!validateName(formData.name)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: true
      }));
      valid = false;
    }

    if (!validateEmail(formData.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: true
      }));
      valid = false;
    }

    if (!validatePhone(formData.phone)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: true
      }));
      valid = false;
    }

    if (!validateMessage(formData.message)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        message: true
      }));
      valid = false;
    }

    if (valid) {
      setLoading(true);
      try {
        await createContactUsMessage(formData);
        console.log('Successfully sent us a message.');
        setFormError('');
        setSubmitted(true);
      } catch (error) {
        console.error('Error sending message: ', error);
        setFormError("An unexpected error occurred. Please try again later.");
      }
      setLoading(false);
    }
  }

  return (
    <div style={{
      height: 'var(--router-height)',
      display: 'flex',
      alignItems: 'center',
      overflowY: 'auto',
      justifyContent: 'center'
    }}>
      <Container maxWidth="md">
        <Paper elevation={6} sx={{
          padding: { xs: 2, sm: 3, md: 4 },
          margin: 'auto',
          maxWidth: '100%',
        }}>
          {submitted ? (
            <>
              <Typography variant="h4" align="center" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
                Thank you for contacting us!
              </Typography>
              <Typography variant="h6" align="center" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>
                We will get back to you as soon as possible.
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h4" align="center" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
                Contact Us
              </Typography>
              <Box component="form" noValidate autoComplete="off" sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Full Name"
                      name="name"
                      variant="outlined"
                      onChange={handleInputChange}
                      error={errors.name}
                      helperText={errors.name ? "Name cannot be empty" : ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      variant="outlined"
                      onChange={handleInputChange}
                      error={errors.email}
                      helperText={errors.email ? "Invalid email format" : ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      name="phone"
                      variant="outlined"
                      onChange={handleInputChange}
                      error={errors.phone}
                      helperText={errors.phone ? "Invalid phone format" : ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth error={errors.type}>
                      <InputLabel>Query Type</InputLabel>
                      <Select
                        value={formData.type}
                        label="Query Type"
                        onChange={handleChange}
                      >
                        <MenuItem value={0}>General</MenuItem>
                        <MenuItem value={1}>Bug / Issue</MenuItem>
                        <MenuItem value={2}>Help</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      label="Message"
                      name="message"
                      variant="outlined"
                      onChange={handleInputChange}
                      error={errors.message}
                      helperText={errors.message ? "Message cannot be empty" : ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="accent"
                      onClick={handleSaveClick}
                      disabled={loading}
                      sx={{ height: '48px', fontSize: { xs: '0.875rem', sm: '1rem' } }}
                    >
                      {loading ? <ProperteeCircularProgress size={24} /> : 'Send Message'}
                    </Button>
                  </Grid>
                </Grid>
                {formError && <Typography color="error" sx={{ mt: 2 }}>{formError}</Typography>}
              </Box>
            </>
          )}
        </Paper>
      </Container>
    </div>
  );
}

export default HomeContact;
