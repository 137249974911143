import React, { useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Container, Typography } from '@mui/material';
import AuthContext from '../utilities/AuthContext';


const SuccessPage = () => {
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const sessionId = queryParams.get('session_id');

        if (sessionId) {
            navigate('/user'); // Redirect to the cancellation page
        }
        else {
            if (currentUser && currentUser.subscriptionId) {
                navigate('/user'); // Redirect to the user page
            }
        }
    }, [currentUser, location, navigate]);
  
    return (
        <div style={{ textAlign: 'center' }}>
            <Container maxWidth="md">
                <Box my={4}>
                    <Typography variant="h4" >
                        Payment Succeeded
                    </Typography>
                </Box>
                <Box my={4}>
                    <Typography variant="h6" >
                        Congratulations! Your payment was successful. You are now subscribed to Propertee Agent.
                    </Typography>
                </Box>
            </Container>
        </div>
    );
};

export default SuccessPage;
