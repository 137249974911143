import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AuthContext from './AuthContext';

import ProperteeCircularProgress from '../components/ProperteeCircular.js';

const ProtectedRoute = ({ component: Component, requiredRole }) => {
  const { currentUser, loading } = useContext(AuthContext);
  let location = useLocation();

  // Check if the current path is for check-in
  const isCheckInRoute = location.pathname.startsWith('/check-in');

  // Get the guest check-in doc id from the path
  const guestCheckInDocId = location.pathname.split('/')[2];

  // Get the home open id from the path
  const homeOpenId = location.pathname.split('/')[3];

  // If still loading, render the loader
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'var(--router-height)' }}>
        <ProperteeCircularProgress />
      </div>
    );
  }

  // Allow unauthenticated access if it's the check-in route
  if (isCheckInRoute) {
    return <Component />;
  }

  // If not loading and user isn't authenticated, redirect to login. If it's coming from the checkin screen then allow unauthenticated access.
  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location, guestCheckInDocId: guestCheckInDocId, homeOpenId: homeOpenId }} />;
  }

  // If requiredRole is set and user's role doesn't match, redirect to appropriate page
  if (requiredRole !== undefined && currentUser.role !== requiredRole) {
    return <Navigate to="/not-authorized" />;
  }

  // If user is an agent but not approved, redirect to user page.
  if (currentUser.role === 1 && !currentUser.approved) {
    if (location.pathname === '/user') {
      return <Component />;
    }
    else {
      return <Navigate to="/user" />;
    }
  }

  // If user is an agent but doesn't have a subscription, redirect to user page.
  if (currentUser.role === 1 && !currentUser.subscriptionId) {
    if (location.pathname === '/payments' || location.pathname === '/success' || location.pathname === '/cancel') {
      return <Component />;
    }
    else if (location.pathname === '/user') {
      return <Component />;
    } else {
      return <Navigate to="/user" />;
    }
  }

  // If user is authenticated, role matches, and loading is complete, render the component
  return <Component />;
};

export default ProtectedRoute;
