import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, styled, Button } from '@mui/material';
import VPNKeyIcon from '@mui/icons-material/VpnKey';
import PersonIcon from '@mui/icons-material/Person';
import backgroundImage from '../assets/Feature-Image.jpg';
import backgroundImage2 from '../assets/Feature-Image2.jpg';

const features = [
  {
    id: 'homebuyers',
    Icon: VPNKeyIcon,
    title: "Homebuyers",
    content: "Sign in using QR codes at home opens, and make it easier to interact with agents.",
    details: [
      "Check into any home open at the click of a button.",
      "Make offers directly on properties on check in.",
      "Make dealing with agents a breeze.",
      "Cost: Free"
    ],
    backgroundImage: backgroundImage, // First feature image
  },
  {
    id: 'agents',
    Icon: PersonIcon,
    title: "Agents",
    content: "Bring listing and offer management into the modern age.",
    details: [
      "Create and manage listings at the click of a button.",
      "Get buyers offering directly without needing to chase them.",
      "Set up QR codes for home open sign ins. No more paper sign ins.",
      "Cost: $99 / mth",
    ],
    backgroundImage: backgroundImage2, // Second feature image
  },
];

const FeatureBox = styled(
  ({ isexpanded, totalfeatures, expandedId, backgroundImage, ...otherProps }) => <Box {...otherProps} />
)(({ theme, isexpanded, totalfeatures, expandedId, backgroundImage }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  textAlign: 'center',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid #ccc',
  boxShadow: isexpanded ? '0 8px 16px rgba(0, 0, 0, 0.15)' : '0 4px 8px rgba(0, 0, 0, 0.1)',
  transition: theme.transitions.create(['width', 'height', 'opacity', 'box-shadow', 'border-color'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  [theme.breakpoints.up('sm')]: {
    width: isexpanded ? '200%' : `${100 / totalfeatures}%`,
    height: '100%',
    '&:hover': {
      width: isexpanded ? '300%' : `calc(${100 / totalfeatures}% + 10px)`,
      boxShadow: '0 12px 20px rgba(0, 0, 0, 0.2)',
      borderColor: '#bbb',
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: expandedId
      ? isexpanded
        ? `calc(var(--router-height) * 0.8)`
        : `calc(var(--router-height) * 0.3 / (${totalfeatures} - 1))`
      : `calc(var(--router-height) / ${totalfeatures})`,
    borderBottom: '1px solid #ddd',
    '&:hover': {
      height: expandedId
        ? isexpanded
          ? `calc(var(--router-height) * 0.7)`
          : `calc(var(--router-height) * 0.3 / (${totalfeatures} - 1))`
        : `calc(var(--router-height) / ${totalfeatures})`,
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
    },
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    transition: 'opacity 0.5s ease',
    zIndex: 1,
    opacity: isexpanded ? 0 : 0.5,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  '&:hover::before': {
    opacity: isexpanded ? 0 : 0.6,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  '*': {
    position: 'relative',
    zIndex: 2,
  },
}));

function Features() {
  const [expandedId, setExpandedId] = useState(null);
  const [hoveredId, setHoveredId] = useState(null);
  const navigate = useNavigate();

  const handleExpandClick = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const handleButtonClick = (id) => {
    if (id === 'agents') {
      navigate('/agent-signup');
    } else {
      navigate('/login');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: 'var(--router-height)',
        overflow: 'hidden',
        flexDirection: { xs: 'column', sm: 'row' },
      }}
    >
      {features.map(({ id, Icon, title, content, details, backgroundImage }) => (
        <FeatureBox
          key={id}
          isexpanded={expandedId === id}
          expandedId={expandedId}
          totalfeatures={features.length}
          backgroundImage={backgroundImage}
          onClick={() => handleExpandClick(id)}
          onMouseEnter={() => setHoveredId(id)}
          onMouseLeave={() => setHoveredId(null)}
        >
          <Box sx={{ position: 'relative', zIndex: 3, mb: 2 }}>
            <Icon sx={{ fontSize: '3rem', color: hoveredId === id ? 'primary.main' : 'text.secondary' }} />
          </Box>

          <Typography
            variant="h5"
            sx={{
              color: hoveredId === id ? 'primary.dark' : 'text.primary',
              fontWeight: 'bold',
              mb: 1,
            }}
          >
            {title}
          </Typography>

          {expandedId === id && (
            <>
              <Typography
                variant="body1"
                sx={{
                  p: 2,
                  color: 'text.secondary',
                  textAlign: 'center',
                  fontSize: { sm: '0.95rem', md: '1.3rem' },
                  lineHeight: 1.6,
                }}
              >
                {content}
              </Typography>

              {details.map((detail, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  sx={{
                    color: 'text.secondary',
                    textAlign: 'center',
                    fontSize: { sm: '0.85rem', md: '1.1rem' },
                    lineHeight: 1.5,
                  }}
                >
                  {detail}
                </Typography>
              ))}
              <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' }, mt: 2 }}>
                <Button
                  variant="containedAccent"
                  color="primary"
                  onClick={() => handleButtonClick(id)}
                  sx={{
                    width: { xs: '100%', sm: 'auto' },
                    fontWeight: 'medium',
                    pt: 1,
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                >
                  {id === 'agents' ? 'Agent Sign Up' : 'Get Started'}
                </Button>
              </Box>
            </>
          )}
        </FeatureBox>
      ))}
    </Box>
  );
}

export default Features;
