import { createTheme } from '@mui/material/styles';


//used to readjust the header and the footer based on the device
function adjustViewportHeight() {
  const actualVh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--actual-vh', `${actualVh}px`);
}

window.addEventListener('resize', adjustViewportHeight);
window.addEventListener('orientationchange', adjustViewportHeight);

adjustViewportHeight();

// Create a theme instance with customized properties
const theme = createTheme({
  palette: {
    primary: {
      main: '#000000', 
    },
    secondary: {
      main: '#eaf0ed', 
    },
    error: {
      main: '#FF0000', 
    },
    background: {
      default: '#f8f8ff', 
    },
    text: {
      primary: '#000000', 
      background: '#f8f8ff', 
    },
    accent: {
      main: '#6193c9', 
    },
    pale: {
      main: '#a8c2e0', 
    },
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          color: '#000000',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          color: '#000000',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#000000',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '48px',
        },
        containedAccent: {
          backgroundColor: '#6193c9',
          border: '1px solid gray',
          color: '#000000',
          '&:hover': {
            backgroundColor: '#346498',
          },
        },
        warning: {
          backgroundColor: '#000000',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#202020',
          },
        },
        outlined: {
          backgroundColor: '#ffffff',
          border: '1px solid gray',
          color: '#000000',
          '&:hover': {
            backgroundColor: 'var(--accent-pale)',
            border: '1px solid gray',
          },
        },
        wide: { // Style for wide button
          width: '100%',
          padding: '12px 24px',
        },
      },
    },
  },
});

// Adding custom variants to the theme, including the 'wide' variant
theme.components.MuiButton.variants = [
  {
    props: { variant: 'contained', color: 'accent' },
    style: {
      backgroundColor: theme.palette.accent.main,
      color: '#000000',
      '&:hover': {
        backgroundColor: theme.palette.accent.main,
      },
    },
  },
  {
    props: { variant: 'wide' },
    style: {
      width: '100%',
      margin: '5px 0 5px 0',
      padding: '12px 24px',
      backgroundColor: theme.palette.accent.main,
      color: '#000000',
      '&:hover': {
        backgroundColor: theme.palette.accent.main,
      },
    },
  },
  {
    props: { variant: 'wide-outlined', color: 'primary' },
    style: {
      width: '100%',
      margin: '5px 0',
      padding: '12px 24px',
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.pale.main,
        borderColor: theme.palette.pale.main,
      },
    },
  }
];

export default theme;
