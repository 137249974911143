import ParkingIcon from '@mui/icons-material/DirectionsCar';
import BathroomIcon from '@mui/icons-material/Bathtub'; // Replace with actual import
import BedroomIcon from '@mui/icons-material/Bed';
import properteeLogo from '../assets/Propertee.PNG';
import React from 'react';


const PdfContent = ({ propertyDetails, qrImage, propOne, propTwo, agentDetails }) => {

  const widthPx = 210; // A4 width in px
  const heightPx = 295; // A4 height in px
  const formattedAddress = propertyDetails.address.replace(/,/g, ' -'); // Replace commas with dashes
  const qrPadding = 5.29;
  const spacing = 10.58;
  const qrSize = 31.75; // QR image size
  const qrTotalSize = qrSize + 2 * qrPadding; // Total size including padding
  const homeInfoWidth = widthPx - 2 * spacing - qrTotalSize - qrTotalSize; // Width of home info box
  const topPosition = '25.46mm'; // Adjusted top position
  const navyBlue = '#000000';

  const formattedPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'AUD',
  }).format(propertyDetails.price);

  // Splitting address into street and suburb
  const [streetAddress, suburb] = propertyDetails.address.split(',');

  // Additional words
  const additionalWords = ["Family", "Comfort", "Future", "Security", "WELCOME TO YOUR NEW HOME"];
  const randomMargin = () => Math.floor(Math.random() * 10.58) + 5.29;

  // Constructing the overlay text elements
  const overlayTextElements = additionalWords.map((word, index) => {
    if (index === additionalWords.length - 1) {
      // No margin for the last word
      return <span key={index}>{word}</span>;
    } else {
      // Random margin for other words
      return <span key={index} style={{ marginRight: `${randomMargin()}mm` }}>{word}</span>;
    }
  });

  return (
    <div style={{
      position: 'relative',
      width: `${widthPx}mm`,
      height: `${heightPx}mm`,
      backgroundColor: '#ddd',
      color: '#000',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      boxSizing: 'border-box',
      fontFamily: 'Arial, sans-serif',
    }}>

      <header style={{
        width: '100%',
        backgroundColor: navyBlue,
        color: '#fff',
        textAlign: 'center',
        padding: '5.29mm 0',
        textTransform: 'uppercase',
        position: 'relative',
        zIndex: 102
      }}>
        <h1 style={{
          margin: '0 0 2mm 0',
          textAlign: 'left',
          paddingLeft: '10.58mm',
          fontSize: '26px',
          whiteSpace: 'nowrap', // Prevents the text from wrapping to a new line
          overflow: 'hidden', // Hides text that overflows the container
          textOverflow: 'ellipsis', // Adds an ellipsis to overflowing text
          maxWidth: '100%', // Ensures the text doesn't exceed the header width
        }}>
          {formattedAddress}
        </h1>
        <p style={{
          margin: '2mm 0 0 0',
          textAlign: 'left',
          paddingLeft: '10.58mm',
          fontSize: '18px',
          fontWeight: 'bold',
        }}>
          {propertyDetails.priceParameter === '*' ? 
            'Open to Offers' 
          : 
            <>{formattedPrice}</>
          }
        </p>
      </header>

      {/* Light Effect Gradient */}
      <div style={{
        position: 'absolute',
        top: '0', // Starting from the top of the container
        right: '0', // Align to the top right corner
        width: '100%', // Cover the full width
        height: '100%', // Height of the gradient effect
        backgroundImage: `linear-gradient(250deg, rgba(255, 255, 255, 0.5), transparent 200mm)`,
        zIndex: 104 // Ensure this is above the main content but below the top elements
      }}></div>

      {/* Gradient overlay*/}
      <div style={{
        position: 'absolute',
        top: topPosition, // Bottom of the header
        left: '15%',
        width: '70%',
        height: `${qrTotalSize + 10.588}mm`, // Height to cover up to bottom of QR and components
        backgroundImage: `linear-gradient(to bottom, ${navyBlue} 10%, transparent 100%)`,
        zIndex: 101 // Lower than header and other components
      }}></div>

      {/* side trimmings*/}
      <div style={{
        position: 'absolute',
        top: '5.29mm', // Bottom of the header
        left: 0,
        width: '10.58mm',
        height: `${qrTotalSize}mm`, // Height to cover up to bottom of QR and components
        backgroundColor: `${navyBlue}`,
        zIndex: 101 // Lower than header and other components
      }}></div>

      <div style={{
        position: 'absolute',
        top: '5.29mm', // Bottom of the header
        right: 0,
        width: '10.58mm',
        height: `${qrTotalSize}mm`, // Height to cover up to bottom of QR and components
        backgroundColor: `${navyBlue}`,
        zIndex: 101 // Lower than header and other components
      }}></div>

      {/* Overlay Text */}
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'absolute',
        left: '11.9mm',
        right: '11.9mm',
        bottom: 'calc(70% + 7.94mm)', // Adjust as needed
        color: '#fff',
        fontSize: '2.65mm', // Smaller font size
        zIndex: 103
      }}>
        <span>{streetAddress.trim()} <strong>{suburb.trim()}</strong></span>
        <div>{overlayTextElements}</div>
      </div>

      <img src={propOne} alt="Property 1" style={{
        width: 'auto',
        height: '40%', // Set your desired height here
        position: 'relative',
        top: '0mm',
        zIndex: 3
      }} />

      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: '52.91mm', // Set to your desired gap height
        alignItems: 'center',
      }}>
        {/* Bedrooms Section */}
        <div style={{
          textAlign: 'center',
          flex: 1,
          overflow: 'visible',
          position: 'relative', // Relative positioning for absolute positioning of the icon
        }}>
          {/* Number of Bedrooms */}
          <div style={{
            fontSize: '15.86mm',
            fontWeight: 'bold',
            color: navyBlue,
          }}>
            {propertyDetails.bedrooms}
          </div>

          {/* Container for Bedroom Icon */}
          <div style={{
            position: 'absolute',
            top: '0',
            left: '5.29mm',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '10.58mm', // Adjust width as needed
            height: '10.58mm', // Adjust height as needed
          }}>
            <BedroomIcon style={{
              color: navyBlue,
              fontSize: '10.58mm',
            }} />
          </div>

          {/* "Bedrooms" Text */}
          <div style={{
            color: navyBlue,
          }}>
            {propertyDetails.bedrooms > 1 ? 'Bedrooms' : 'Bedroom'}
          </div>
        </div>

        <div style={{ height: '80%', width: '0.26mm', backgroundColor: '#aaa' }}></div>

        {/* Bathrooms Section */}
        <div style={{
          textAlign: 'center',
          flex: 1,
          position: 'relative', // Relative positioning for absolute positioning of the icon
        }}>
          {/* Container for Bathroom Icon */}
          <div style={{
            position: 'absolute',
            top: '0',
            left: '5.29mm',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '10.58mm', // Adjust width as needed
            height: '10.58mm', // Adjust height as needed
          }}>
            <BathroomIcon style={{
              color: navyBlue,
              fontSize: '10.58mm',
            }} />
          </div>

          {/* Number of Bathrooms */}
          <div style={{
            fontSize: '15.86mm',
            fontWeight: 'bold',
            color: navyBlue,
          }}>
            {propertyDetails.bathrooms}
          </div>

          {/* "Bathrooms" Text */}
          <div style={{
            color: navyBlue,
          }}>
            {propertyDetails.bathrooms > 1 ? 'Bathrooms' : 'Bathroom'}
          </div>
        </div>

        <div style={{ height: '80%', width: '0.26mm', backgroundColor: '#aaa' }}></div>

        {/* Parking Section */}
        <div style={{
          textAlign: 'center',
          flex: 1,
          position: 'relative', // Relative positioning for the icon
        }}>
          {/* Container for Parking Icon */}
          <div style={{
            position: 'absolute',
            top: '0',
            left: '5.29mm',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '10.58mm', // Adjust width as needed
            height: '10.58mm', // Adjust height as needed
          }}>
            <ParkingIcon style={{
              color: navyBlue,
              fontSize: '10.58mm',
            }} />
          </div>

          {/* Number of Parking Spots */}
          <div style={{
            fontSize: '15.86mm',
            fontWeight: 'bold',
            color: navyBlue,
          }}>
            {propertyDetails.parkingSpots}{/* ...i dont even want to bother with this... */}
          </div>

          {/* "Parking" Text */}
          <div style={{
            color: navyBlue,
          }}>
            {propertyDetails.parkingsSpots > 1 ? 'Parkings' : 'Parking'}
          </div>
        </div>
      </div>

      <div style={{
        position: 'absolute',
        left: `${spacing}mm`,
        top: topPosition,
        backgroundColor: '#fff',
        padding: `${qrPadding}mm`,
        boxSizing: 'border-box',
        height: `${qrTotalSize}mm`,
        width: `${homeInfoWidth}mm`,
        zIndex: 105
      }}>
        <h3 style={{ margin: '0 0 2.65mm 0', color: navyBlue, fontWeight: 'bold', fontSize: '4.78mm' }}>Home Open Details:</h3>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          fontSize: '3.7mm',
          margin: '0',
          width: '100%', // Ensures the div fills its container
        }}>
          <div style={{ marginBottom: '2.65mm', marginLeft: '5.29mm' }}>
            <strong>Time: </strong>
            <span style={{ fontWeight: 'normal' }}>{propertyDetails.openHouseDate}</span>
          </div>

          <div style={{ marginBottom: '2.65mm', marginLeft: '5.29mm' }}>
            <strong>Agent Name: </strong>
            <span style={{ fontWeight: 'normal' }}>{agentDetails.name}</span>
          </div>

          <div style={{ marginBottom: '2.65mm', marginLeft: '5.29mm' }}>
            <strong>Phone: </strong>
            <span style={{ fontWeight: 'normal' }}>{agentDetails.phoneNumber}</span>
          </div>

          <div style={{ marginBottom: '2.65mm', marginLeft: '5.29mm' }}>
            <strong>Email: </strong>
            <span style={{ fontWeight: 'normal' }}>{agentDetails.email}</span>
          </div>
        </div>
      </div>

      <div style={{
        position: 'absolute',
        left: `${homeInfoWidth + spacing}mm`, // Adjust left position
        top: topPosition,
        color: '#fff',
        width: `${qrTotalSize - 5.29}mm`, // Adjust width
        textAlign: 'center',
        padding: '2.65mm',
        paddingTop: `${qrTotalSize / 2 - 5.29}mm`,
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '5.29mm',
        fontWeight: 'bold',
        zIndex: 103
      }}>
        Scan the QR to check in
      </div>

      <div style={{
        position: 'absolute',
        right: `${spacing}mm`,
        top: topPosition,
        width: `${qrTotalSize}mm`,
        height: `${qrTotalSize}mm`,
        backgroundColor: '#fff',
        boxSizing: 'border-box',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${qrPadding}mm`,
        zIndex: 103
      }}>

        <img src={qrImage} alt="QR Code" style={{ width: `${qrSize}mm`, height: `${qrSize}mm` }} />
        <span style={{ fontSize: '3.17mm' }}>Scan me</span>
      </div>
      {/* Image propTwo */}

      <img src={propTwo} alt="Property 2" style={{
        width: '100%',
        height: 'auto',
      }} />

      <footer style={{
        backgroundColor: navyBlue,
        color: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1.32mm',
        width: '100%',
      }}>
        <div style={{ display: 'flex', marginLeft: '2.65mm', alignItems: 'center', height: '100%' }}>
          <img src={properteeLogo} alt="Vectech Logo" className="navbar-logo" />
        </div>
        <div style={{ marginRight: '2.65mm' }}>
          <p>www.propertee.org</p>
          <p>&copy; {new Date().getFullYear()} Propertee. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default PdfContent;
