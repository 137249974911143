
import React from 'react';
import { Box, Typography } from '@mui/material';
import BackgroundImage from '../assets/background-building.png';
import { useInView } from 'react-intersection-observer';

function MovingText() {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  return (
    <Box
      sx={{
        width: '100%',
        height: 'var(--router-height)',  // Full viewport height to ensure centering
        display: 'flex',
        alignItems: 'center',  // Center vertically
        justifyContent: 'center',  // Center horizontally
        backgroundImage: `
          linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 30%, rgba(255, 255, 255, 1) 100%),
          url(${BackgroundImage})
        `,
        backgroundSize: 'cover',      // Cover the entire Box
        backgroundPosition: 'center', // Center the image
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Box
        ref={ref}
        sx={{
          padding: { xs: '20px', sm: '30px', md: '40px' },  // Responsive padding
          maxWidth: '600px',  // Limit text width for readability
          opacity: inView ? 1 : 0,
          transform: inView ? 'translateY(0px)' : 'translateY(50px)',
          transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
          color: '#000000',
          textAlign: 'center',  // Center align the text
        }}
      >
        <Typography variant="h4" fontWeight="bold" sx={{
            mb: { xs: '8px', sm: '10px' },
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.125rem' }, 
          }}
        >
          What can be improved in real estate sales today?
        </Typography>
        <Typography variant="h5" sx={{
            mb: { xs: '16px', sm: '20px' },
            fontSize: { xs: '1.25rem', sm: '1.5rem' },
          }}
        >
          The property market has opportunities to become more efficient, cost-effective, and seamless.
        </Typography>
        <Typography variant="subtitle1" sx={{
            mb: { xs: '10px', sm: '15px' },
            fontSize: { xs: '0.9rem', sm: '1rem' },
          }}
        >
          We believe there’s room to simplify processes, reduce complexities, and create better experiences for everyone involved.
        </Typography>
        <Typography variant="subtitle1" sx={{
            mb: { xs: '16px', sm: '20px' },
            fontSize: { xs: '0.9rem', sm: '1rem' },
          }}
        >
          Our focus is on empowering buyers, sellers, and agents alike—working together to make real estate transactions smoother and more rewarding.
        </Typography>
        <Typography variant="h5" sx={{
            fontSize: { xs: '1.25rem', sm: '1.5rem' },
          }}
        >
          We’re here to drive that change.
        </Typography>
      </Box>
    </Box>
  );
}

export default MovingText;

