
import React, { useState, useRef } from 'react';
import { Typography, Paper, Button, Box, Collapse, useMediaQuery, useTheme } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import PreviewIcon from '@mui/icons-material/Visibility';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DOMPurify from 'dompurify';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const EditDescriptionCard = ({ property, onSaveDescription, onEditAddress }) => {
  const [isEditingDescription, setIsEditingDescription] = useState(!property.description);
  const [editedDescription, setEditedDescription] = useState(property.description || '');
  const [isDescriptionEmpty, setIsDescriptionEmpty] = useState(false);
  const [expanded, setExpanded] = useState(true); // Initially expanded
  const [isPreviewingDescription, setIsPreviewingDescription] = useState(false); // New state for preview

  const componentRef = useRef(null); // Reference to the component
  const theme = useTheme();
  const isWideScreen = useMediaQuery(theme.breakpoints.up('md')); // Detect if screen is wide (e.g., 'md' breakpoint)

  const handleDescriptionEditClick = () => {
    setIsEditingDescription(true);
    setIsDescriptionEmpty(false);
    setIsPreviewingDescription(false); // Ensure we are not in preview mode
  };

  //TODO: do we need this
  // const handleDescriptionCancelClick = () => {
  //   setIsEditingDescription(false);
  //   setEditedDescription(property.description || '');
  //   setIsDescriptionEmpty(false);
  //   setIsPreviewingDescription(false);
  // };

  const handleDescriptionClearClick = () => {
    setEditedDescription('');
    setIsDescriptionEmpty(true);
  };

  const handleDescriptionSaveClick = () => {
    if (editedDescription.trim() === '' || editedDescription === '<p><br></p>') {
      setIsDescriptionEmpty(true);
    } else {
      onSaveDescription(editedDescription);
      setIsEditingDescription(false);
      setIsPreviewingDescription(false); // Reset preview mode after saving
    }
  };

  const handleDescriptionPreviewClick = () => {
    // Toggle preview mode
    setIsPreviewingDescription(true);
  };

  const handleDescriptionBackToEditClick = () => {
    // Exit preview mode back to editing
    setIsPreviewingDescription(false);
  };

  const handleDescriptionChange = (content) => {
    setEditedDescription(content);
    setIsDescriptionEmpty(content.trim() === '' || content === '<p><br></p>');
  };

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  const handleEditAddressClick = () => {
    if (onEditAddress) {
      onEditAddress();
    }
    if (isWideScreen) {
      // Scroll to the top of the page on wide screens
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (componentRef.current) {
      // Scroll so that the bottom of the component aligns with the top of the viewport on narrow screens
      const rect = componentRef.current.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const elementBottom = rect.bottom + scrollTop;
      const scrollToPosition = elementBottom - rect.height; // Align bottom of component with top of viewport
      const maxScrollTop = document.documentElement.scrollHeight - window.innerHeight;
      const top = Math.min(scrollToPosition, maxScrollTop);
      window.scrollTo({ top: top, behavior: 'smooth' });
    }
  };

  const blueLineStyle = {
    borderBottom: '2px solid var(--accent-color)',
    width: expanded ? '100%' : '40%',
    transition: 'width 0.5s ease-in-out',
    marginBottom: '16px',
  };

  // React Quill modules and formats
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false, // This can prevent extra formatting
    },
  };

  // const formats = [
  //   'header',
  //   'bold',
  //   'italic',
  //   'underline',
  //   'strike',
  //   'color',
  //   'background',
  //   'list',
  //   'bullet',
  //   'align',
  // ];

  return (
    <Paper
      elevation={0}
      sx={{
        maxWidth: 1000,
        margin: 'auto',
        backgroundColor: 'transparent',
        padding: 4,
        paddingTop: 0
      }}
      ref={componentRef} // Attach the ref to the Paper component
    >
      {/* Address Section */}
      <Box sx={{ mb: 5 }}>
        <Box display="flex" alignItems="center">
          <LocationOnIcon sx={{ color: 'text.secondary', mr: 1 }} /> {/* Add the location icon */}
          <Typography variant="subtitle1" color="text.secondary" sx={{ flexGrow: 1 }}>
            {property.address || 'No address provided'}
          </Typography>
          {expanded && (
            <Button
              startIcon={<EditIcon />}
              onClick={handleEditAddressClick}
              sx={{ color: 'var(--accent-color)' }}
            >
              Edit Address
            </Button>
          )}
        </Box>
        <div style={blueLineStyle}></div>
      </Box>

      {/* Collapse the rest when not expanded */}
      <Collapse in={expanded} timeout="auto" unmountOnExit={false}>
        {/* Description Section */}
        <Box>
          {isEditingDescription ? (
            isPreviewingDescription ? (
              // Preview Mode
              <Box>
                <Typography
                  variant="body1"
                  sx={{ whiteSpace: 'pre-wrap' }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(editedDescription || 'No description provided.'),
                  }}
                />
                <Box
                  sx={{
                    mt: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handleDescriptionBackToEditClick}
                    sx={{
                      color: 'var(--primary-color)',
                      backgroundColor: '#ffffff',
                      '&:hover': {
                        backgroundColor: 'var(--accent-pale)',
                      },
                    }}
                  >
                    Back to Edit
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={handleDescriptionSaveClick}
                    sx={{
                      backgroundColor: 'var(--accent-color)',
                      '&:hover': {
                        backgroundColor: 'var(--accent-color-dark)',
                      },
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            ) : (
              // Editing Mode
              <Box>
                <Box sx={{ '.ql-editor': { fontSize: '16px' } }}>
                  <ReactQuill
                    value={editedDescription}
                    onChange={handleDescriptionChange}
                    modules={modules}
                    //formats={formats}
                    placeholder="Enter the property description here..."
                  />
                </Box>
                {isDescriptionEmpty && (
                  <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                    Description cannot be empty
                  </Typography>
                )}
                <Box
                  sx={{
                    mt: 3,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handleDescriptionClearClick}
                    sx={{
                      color: 'var(--primary-color)',
                      backgroundColor: '#ffffff',
                      '&:hover': {
                        backgroundColor: 'var(--accent-pale)',
                      },
                    }}
                  >
                    Clear
                  </Button>
                  <Box>
                    <Button
                      variant="contained"
                      onClick={handleDescriptionPreviewClick}
                      startIcon={<PreviewIcon />}
                      sx={{
                        mr: 1,
                        backgroundColor: 'var(--accent-color)',
                        '&:hover': {
                          backgroundColor: 'var(--accent-color-dark)',
                        },
                      }}
                    >
                      Preview
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<SaveIcon />}
                      onClick={handleDescriptionSaveClick}
                      sx={{
                        backgroundColor: 'var(--accent-color)',
                        '&:hover': {
                          backgroundColor: 'var(--accent-color-dark)',
                        },
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Box>
            )
          ) : (
            // Not Editing
            <Box>
              <Typography
                variant="body1"
                sx={{ whiteSpace: 'pre-wrap', cursor: 'pointer' }}
                onClick={handleDescriptionEditClick}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(property.description || 'Click here to add a description'),
                }}
              />
              <Button
                startIcon={<EditIcon />}
                onClick={handleDescriptionEditClick}
                sx={{ mt: 2, color: 'var(--accent-color)' }}
              >
                Edit Description
              </Button>
            </Box>
          )}
        </Box>
      </Collapse>

      {/* Expand/Collapse Button */}
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <Button onClick={toggleExpanded}>
          {expanded ? (
            <>
              Hide Editor
              <ExpandLessIcon sx={{ marginLeft: '8px' }} />
            </>
          ) : (
            <>
              Edit Description
              <ExpandMoreIcon sx={{ marginLeft: '8px' }} />
            </>
          )}
        </Button>
      </Box>
    </Paper>
  );
};

export default EditDescriptionCard;

