import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import logo from '../assets/propertee-logo.svg';
import DrawerList from '../components/DrawerList';
import AuthContext from '../utilities/AuthContext';
import AlertBanner from './AlertBanner';


const Navbar = () => {
  const { currentUser, resendVerificationEmail, logout } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isBannerOpen, setBannerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [loggingOut, setLoggingOut] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isAgent = currentUser && currentUser.role === 1;
  const isAdmin = currentUser && currentUser.role === 99;
  const isApproved = currentUser && currentUser.approved;
  const isVerified = currentUser && currentUser.emailVerified;
  const [bannerMessage, setBannerMessage] = useState('');
  const [severityLevel, setSeverityLevel] = useState('info');
  const [showVerificationButton, setShowVerificationButton] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (!isMobile) {
        setDrawerOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  //----- banner management

  const hideAlert = () => {
    setBannerOpen(false);
  };

  //---- banner ends

  useEffect(() => {
    if (currentUser) {
      if (isAgent) {
        if (isVerified && isApproved) {
          setBannerOpen(false);
          setShowVerificationButton(false);
        } else if (isVerified && !isApproved) {
          setBannerMessage(
            'Your account is currently unapproved, please wait for the admin to approve you and check back soon.'
          );
          setBannerOpen(true);
          setShowVerificationButton(false);
        } else if (!isVerified && isApproved) {
          setBannerMessage(
            'Your account is currently unverified. Please check your emails and verify your account.'
          );
          setBannerOpen(true);
          setShowVerificationButton(true);
        } else {
          setBannerMessage(
            'Your account is unapproved and unverified. Please check your emails to verify your account while you wait for admin approval.'
          );
          setBannerOpen(true);
          setShowVerificationButton(true);
        }
      } else {
        if (!isVerified) {
          setBannerMessage(
            'Your account is currently unverified. Please check your emails and verify your account.'
          );
          setBannerOpen(true);
          setShowVerificationButton(true);
        } else {
          setBannerOpen(false);
          setShowVerificationButton(false);
        }
      }
    } else {
      setBannerOpen(false);
      setShowVerificationButton(false);
    }
  }, [currentUser, isVerified, isApproved, isAgent]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleNavigation = (path) => {
    navigate(path);
    setDrawerOpen(false);
  };

  const handleLogout = async () => {
    setLoggingOut(true);
    setDrawerOpen(false);
    logout();
    console.log('Logout clicked');
    navigate('/login');
    setLoggingOut(false);
  };

  const handleVerification = async () => {
    try {
      const response = await resendVerificationEmail();
      if (response.status === "success") {
        console.log(response.message);
      } else if (response.status === "already_verified") {
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error resending verification email:", error.message);
    }
  };

  const toggleSeverity = () => {
    switch (severityLevel) {
      case 'info':
        setSeverityLevel('warning');
        break;
      case 'warning':
        setSeverityLevel('error');
        break;
      case 'error':
        setSeverityLevel('info');
        break;
      default:
        break;
    }
  }

  const drawerList = (
    <DrawerList
      handleNavigation={handleNavigation}
      handleLogout={handleLogout}
      isAgent={isAgent}
      isAdmin={isAdmin}
      currentUser={currentUser}
    />
  );

  //====== This is where you set buttons========

  const buttons = [
    ...(showVerificationButton
      ? [
        {
          label: 'Resend Email',
          onClick: handleVerification,
        },
      ]
      : []),
    {
      label: 'Close',
      onClick: hideAlert,
    },
    ...(false ? [{
      label: 'Toggle',
      onClick: toggleSeverity,
    }] : []
    )
  ];

  return (
    <>
      <AppBar
        position="fixed"
        color="primary"
        sx={{
          height: 'var(--header-height)',
          width: '100%',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          '::after': {
            content: '""',
            display: 'block',
            height: '4px',
            backgroundColor: theme.palette.accent.main,
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
          },
        }}
      >
        <Toolbar
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <IconButton color="inherit" component={RouterLink} to="/" sx={{ p: 0 }}>
            <img
              src={logo}
              alt="Logo"
              style={{ height: 'calc(var(--header-height) - 20px)' }}
            />
          </IconButton>
          {isMobile ? (
            <>
              {!currentUser ? (
                <Button color="inherit" component={RouterLink} to="/login">
                  Login
                </Button>
              ) : (
                <IconButton color="inherit" onClick={handleDrawerToggle}>
                  <MenuIcon />
                </IconButton>
              )}
            </>
          ) : (
            <div>
              {currentUser ? (
                <>
                  <Button
                    color="inherit"
                    component={RouterLink}
                    to="/"
                    sx={
                      isActive('/')
                        ? { color: theme.palette.accent.main }
                        : {}
                    }
                  >
                    Home
                  </Button>
                  {isAdmin && (
                    <>
                      <Button
                        color="inherit"
                        component={RouterLink}
                        to="/agent-verification-approval"
                        sx={
                          isActive('/agent-verification-approval')
                            ? { color: theme.palette.accent.main }
                            : {}
                        }
                      >
                        Approvals
                      </Button>
                    </>
                  )}
                  {isAgent && (
                    <>
                      <Button
                        color="inherit"
                        component={RouterLink}
                        to="/events"
                        sx={
                          isActive('/events')
                            ? { color: theme.palette.accent.main }
                            : {}
                        }
                      >
                        My Events
                      </Button>
                      <Button
                        color="inherit"
                        component={RouterLink}
                        to="/search"
                        sx={
                          isActive('/search')
                            ? { color: theme.palette.accent.main }
                            : {}
                        }
                      >
                        My Homes
                      </Button>
                    </>
                  )}
                  <Button
                    color="inherit"
                    component={RouterLink}
                    to="/about"
                    sx={
                      isActive('/about')
                        ? { color: theme.palette.accent.main }
                        : {}
                    }
                  >
                    About
                  </Button>
                  <Button
                    color="inherit"
                    component={RouterLink}
                    to="/contact"
                    sx={
                      isActive('/contact')
                        ? { color: theme.palette.accent.main }
                        : {}
                    }
                  >
                    Contact
                  </Button>
                  <Button
                    color="accent"
                    onClick={handleLogout}
                    disabled={loggingOut}
                    sx={{ fontWeight: 'bold' }}
                  >
                    Logout
                  </Button>
                  <IconButton
                    color="inherit"
                    component={RouterLink}
                    to="/user"
                    sx={
                      isActive('/user')
                        ? { color: theme.palette.accent.main }
                        : {}
                    }
                  >
                    <AccountCircleIcon sx={{ fontSize: 30 }} />
                  </IconButton>
                </>
              ) : (
                <>
                  <Button color="inherit" component={RouterLink} to="/login">
                    Login
                  </Button>
                </>
              )}
            </div>
          )}
        </Toolbar>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
        >
          {drawerList}
        </Drawer>
      </AppBar>
      <Box>
        {/* The way im thinking this would work is you would set the message the severity and then at the end you set the is banner open */}
        {/* this way you can pass any warning in */}
        <AlertBanner
          open={isBannerOpen}
          message={bannerMessage}
          severity={severityLevel}
          buttons={buttons}
          onClose={() => console.log('Alert closed')}
        />
      </Box>
    </>
  );
};

export default Navbar;
