import React, { useState, useContext, useEffect } from 'react';
import { Container, Divider, Paper, Typography, TextField, Button, Tab, Tabs, Box, InputAdornment } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthContext from '../utilities/AuthContext';

import ProperteeCircularProgress from '../components/ProperteeCircular.js';


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const AuthPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    firstname: '',
    lastname: '',
    phone: '',
  });
  const { currentUser, login, signup } = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [loginError, setLoginError] = useState('');
  const [signupError, setSignupError] = useState('');

  const [guestCheckInDocId, setGuestCheckInDocId] = useState('');
  const [homeOpenId, setHomeOpenId] = useState('');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setLoading(true);

      if (location.state && location.state.from && location.state.from.pathname.startsWith('/check-in')) {
        // Redirect back to the check-in page
        navigate(location.state.from.pathname, { replace: true });
      } else {
        // Default redirection based on user role
        if (currentUser.role === 1) {
          navigate('/search');
        } else {
          navigate('/');
        }
      }
    }
    else if (location.state && location.state.from && location.state.from.pathname.startsWith('/check-in')) {
      setLoading(true);

      setGuestCheckInDocId(location.state.guestCheckInDocId);
      setHomeOpenId(location.state.homeOpenId);
    }

    setLoading(false);
  }, [currentUser, navigate, location.state]);

  const errorUtility = (type, error) => {
    switch (type) {
      case 0:
        switch (error.code) {
          case 'auth/invalid-credential':
            setLoginError('Your email or password is incorrect');
            break;
          case 'auth/invalid-email':
            setLoginError('Inputted email is not a valid format');
            break;
          case 'auth/insufficient-permission':
            setLoginError('Server permission error');
            break;
          case 'auth/internal-error':
            setLoginError('Server error occurred');
            break;
          case 'auth/invalid-arguement':
            setLoginError('Inputted email or password has invalid input type');
            break;
          case 'auth/too-many-requests':
            setLoginError('Too many attempts used, try again later');
            break;
          default:
            setLoginError('Unexpected error occurred');
            break;
        }
        break;
      default:
        switch (error.code) {
          case 'auth/invalid-email':
            setSignupError('Inputted email is not a valid format');
            break;
          case 'auth/weak-password':
            setSignupError('Password must contain a lowercase, uppercase, number, symbol, and be at least 6 characters or longer');
            break;
          case 'auth/email-already-in-use':
            setSignupError('Inputted email is already taken');
            break;
          case 'auth/insufficient-permission':
            setSignupError('Server permission error');
            break;
          case 'auth/internal-error':
            setSignupError('Server error occurred');
            break;
          case 'auth/invalid-arguement':
            setSignupError('Inputted email or password has invalid input type');
            break;
          default:
            setSignupError('Unexpected error occurred');
            break;
        }
        break;
    }
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setLoginError('');
    setSignupError('');
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCredentials({ ...credentials, [name]: value });

    // Clear error messages on input change
    if (tabValue === 0) {
      setLoginError('');
    } else {
      setSignupError('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && tabValue === 0) {
      handleLogin();
    }
    else if (event.key === 'Enter' && tabValue === 1) {
      handleSignup();
    }
  };

  const handlePhoneFocus = () => {
    let phoneInputValue = credentials.phone;
    if (phoneInputValue.startsWith('+61')) {
      phoneInputValue = phoneInputValue.slice(3).replace(/\s+/g, '');
    }
    setCredentials({ ...credentials, phone: phoneInputValue });
  };

  const handlePhoneBlur = () => {
    let phoneDigits = credentials.phone.replace(/\s+/g, '');

    if (phoneDigits.startsWith('0')) {
      phoneDigits = phoneDigits.slice(1);
    }

    const isValid = /^\d{9}$/.test(phoneDigits);

    if (isValid) {
      const formattedPhone = phoneDigits.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
      setCredentials({ ...credentials, phone: formattedPhone });
    } else {
      setSignupError("Invalid phone number. Please enter a valid Australian phone number with 9 digits.");
      setCredentials({ ...credentials, phone: credentials.phone });
    }
  };

  const handleLogin = async () => {
    setLoading(true);
    setLoginError(''); // Clear previous errors

    try {
      await login(credentials.email, credentials.password);
    }
    catch (error) {
      console.error('Error logging in: ', error);
      errorUtility(0, error);
    }

    setLoading(false);
  };

  const handleSignup = async () => {
    setLoading(true);
    setSignupError(''); // Clear previous errors

    if (credentials.firstname.length < 1 || credentials.lastname.length < 1) {
      setSignupError('First name and last name are required');
      setLoading(false);
      return;
    }

    let phoneDigits = credentials.phone.replace(/\s+/g, '');
    if (phoneDigits.startsWith('0')) {
      phoneDigits = phoneDigits.slice(1);
    }

    const isValid = /^\d{9}$/.test(phoneDigits);

    if (!isValid) {
      setSignupError(
        'Invalid phone number. Please enter a valid Australian phone number with 9 digits.'
      );
      setLoading(false);
      return;
    }

    const fullPhoneNumber = '+61' + phoneDigits;

    try {
      if (guestCheckInDocId && homeOpenId) {
        await signup(credentials.email, credentials.password, credentials.firstname, credentials.lastname, 0, null, guestCheckInDocId, homeOpenId, fullPhoneNumber);
      }
      else {
        await signup(credentials.email, credentials.password, credentials.firstname, credentials.lastname, 0, null, null, null, fullPhoneNumber);
      }
    }
    catch (error) {
      console.error('Error signing up: ', error);
      errorUtility(1, error)
    }

    setLoading(false);
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'var(--router-height)' }}>
        <ProperteeCircularProgress />
      </div>
    )
  }

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} >
        <Typography variant="h4" align="center" sx={{ mt: 3, pt: 3 }}>
          {tabValue === 0 ? 'Login' : 'Sign Up'}
        </Typography>
        <Tabs value={tabValue} onChange={handleChange} centered>
          <Tab label="Login" />
          <Tab label="Sign Up" />
        </Tabs>
        <Divider sx={{ mt: 2, mx: 'auto', width: '90%' }} />
        <TabPanel value={tabValue} index={0}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={credentials.email}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            margin="dense"
            required
          />
          <TextField
            fullWidth
            label="Password"
            name="password"
            type="password"
            value={credentials.password}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            margin="dense"
            required
          />
          <Button fullWidth disabled={loading} variant="contained" color="accent" onClick={handleLogin} sx={{ mt: 1 }}>
            Login
          </Button>
          {loginError && (
            <Typography color="error" align="center" sx={{ mt: 2 }}>
              {loginError}
            </Typography>
          )}
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <TextField
            fullWidth
            label="First Name"
            name="firstname"
            value={credentials.firstname}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            margin="dense"
            required
          />
          <TextField
            fullWidth
            label="Last Name"
            name="lastname"
            value={credentials.lastname}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            margin="dense"
            required
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={credentials.email}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            margin="dense"
            required
          />
          <TextField
            fullWidth
            label="Phone Number"
            name="phone"
            value={credentials.phone}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={handlePhoneFocus}
            onBlur={handlePhoneBlur}
            margin="dense"
            required
            InputProps={{
              startAdornment: <InputAdornment position="start">+61</InputAdornment>,
            }}
          />
          <TextField
            fullWidth
            label="Password"
            name="password"
            type="password"
            value={credentials.password}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            margin="dense"
            required
          />
          <Button fullWidth variant="contained" disabled={loading} color="accent" onClick={handleSignup} sx={{ mt: 1 }}>
            Sign Up
          </Button>
          {signupError && (
            <Typography color="error" align="center" sx={{ mt: 2 }}>
              {signupError}
            </Typography>
          )}
          <Typography variant="body2" align="center" sx={{ pt: 2, mb: 0 }}>
            By clicking sign up you are agreeing to our <a href="/terms">Terms of Use</a> and <a href="/privacy-policy">Privacy Policy</a>.
          </Typography>
          <Typography variant="body2" align="center" sx={{ mb: 0 }}>
            If you are a real estate agent, please sign up <a href="/agent-signup">here</a>.
          </Typography>
        </TabPanel>
      </Paper>
    </Container>
  );
};

export default AuthPage;
