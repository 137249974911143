import React, { useState, useRef, useEffect } from 'react';
import { CardMedia, IconButton, Button, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function ImageSliderUpload({ images = [], setNewImages }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const fileInputRef = useRef(null);
  const [currentImageDataUrl, setCurrentImageDataUrl] = useState(null);

  useEffect(() => {
    if (images.length > 0 && currentImageIndex >= 0 && currentImageIndex < images.length) {
      const file = images[currentImageIndex];
      if (file instanceof File) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setCurrentImageDataUrl(e.target.result);
        };
        reader.readAsDataURL(file);
      } else if (typeof file === 'string') {
        setCurrentImageDataUrl(file);
      } else {
        setCurrentImageDataUrl(null);
      }
    } else {
      setCurrentImageDataUrl(null);
    }
  }, [images, currentImageIndex]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newFiles = [...images, ...Array.from(files)];
    setNewImages(newFiles);
  };

  const handlePreviousImage = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const handleNextImage = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const deleteCurrentImage = (e) => {
    e.stopPropagation();
    const newImages = images.filter((_, index) => index !== currentImageIndex);
    setNewImages(newImages);
    if (currentImageIndex >= newImages.length && newImages.length > 0) {
      setCurrentImageIndex(newImages.length - 1);
    } else if (newImages.length === 0) {
      setCurrentImageIndex(0);
    }
  };

  return (
    <div
      style={{
        border: '2px solid var(--off-blue)',
        position: 'relative',
        height: 'clamp(400px, 60vh, 800px)',
        backgroundColor: '#000000',
      }}
    >
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleFileChange}
        ref={fileInputRef}
        multiple
      />

      {/* Central Upload Button when no images are uploaded */}
      {images.length === 0 && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => fileInputRef.current && fileInputRef.current.click()}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              marginBottom: '16px',
              fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' },
              padding: { xs: '16px', sm: '24px', md: '32px' },
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
              border: '2px solid #007bff',
              color: '#007bff',
              fontWeight: 'bold',
              opacity: 0.5,
              transition: 'opacity 0.3s',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minWidth: { xs: '200px', sm: '250px', md: '300px' },
              minHeight: { xs: '100px', sm: '150px', md: '200px' },
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 1)',
                opacity: 1,
              },
            }}
          >
            Upload Photos
            <CloudUploadIcon
              sx={{ fontSize: { xs: '2rem', sm: '3rem' }, color: '#007bff', mt: 1 }}
            />
          </Button>
        </div>
      )}

      {/* Image Slider and Controls */}
      {images.length > 0 && (
        <>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: '#ffffff',
              position: 'absolute',
              top: '2%',
              left: '46%',
            }}
          >
            {currentImageIndex + 1} / {images.length}
          </Typography>

          <IconButton
            onClick={handlePreviousImage}
            style={{
              position: 'absolute',
              color: '#ffffff',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '50%',
              zIndex: 10,
              top: '50%',
              left: '10px',
              transform: 'translateY(-50%)',
            }}
          >
            <ArrowBackIcon />
          </IconButton>

          <CardMedia
            component="img"
            alt="Uploaded Image"
            image={currentImageDataUrl}
            title="Uploaded Image"
            style={{ width: '100%', height: '100%', objectFit: 'contain', backgroundColor: '#000000' }}
          />

          <IconButton
            onClick={handleNextImage}
            style={{
              position: 'absolute',
              color: '#ffffff',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '50%',
              zIndex: 10,
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
            }}
          >
            <ArrowForwardIcon />
          </IconButton>

          <IconButton
            onClick={deleteCurrentImage}
            style={{
              position: 'absolute',
              color: '#ffffff',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '50%',
              zIndex: 10,
              bottom: '10%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <DeleteForeverIcon />
          </IconButton>
        </>
      )}

      {/* Always Visible Upload Button */}
      <IconButton
        onClick={() => fileInputRef.current && fileInputRef.current.click()}
        style={{
          position: 'absolute',
          color: '#ffffff',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          borderRadius: '50%',
          zIndex: 10,
          top: '10px',
          right: '10px',
        }}
      >
        <CloudUploadIcon />
      </IconButton>
    </div>
  );
}

export default ImageSliderUpload;
