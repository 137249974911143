import React, { useState, useContext } from 'react';
import { Container, Typography, Divider, Paper, TextField, Button, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../utilities/AuthContext';

import ProperteeCircularProgress from '../components/ProperteeCircular.js';


const AgentSignUp = () => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    firstname: '',
    lastname: '',
    role: 1,
    licenseNumber: '',
    phone: '',
  });

  const { signup } = useContext(AuthContext);
  const [signupError, setSignupError] = useState('');

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const errorUtility = (error) => {
    switch (error.code) {
      case 'auth/invalid-email':
        setSignupError('Inputted email is not a valid format');
        break;
      case 'auth/weak-password':
        setSignupError('Password must contain a lowercase, uppercase, number, symbol, and be at least 6 characters or longer');
        break;
      case 'auth/email-already-in-use':
        setSignupError('Inputted email is already taken');
        break;
      case 'auth/insufficient-permission':
        setSignupError('Server permission error');
        break;
      case 'auth/internal-error':
        setSignupError('Server error occurred');
        break;
      case 'auth/invalid-arguement':
        setSignupError('Inputted email or password has invalid input type');
        break;
      default:
        setSignupError('Unexpected error occurred');
        break;
    };
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCredentials({ ...credentials, [name]: value });
    setSignupError('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSignup();
    }
  };

  const handlePhoneFocus = () => {
    let phoneInputValue = credentials.phone;
    if (phoneInputValue.startsWith('+61')) {
      phoneInputValue = phoneInputValue.slice(3).replace(/\s+/g, '');
    }
    setCredentials({ ...credentials, phone: phoneInputValue });
  };

  const handlePhoneBlur = () => {
    let phoneDigits = credentials.phone.replace(/\s+/g, '');

    if (phoneDigits.startsWith('0')) {
      phoneDigits = phoneDigits.slice(1);
    }

    const isValid = /^\d{9}$/.test(phoneDigits);

    if (isValid) {
      const formattedPhone = phoneDigits.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
      setCredentials({ ...credentials, phone: formattedPhone });
    } else {
      setSignupError("Invalid phone number. Please enter a valid Australian phone number with 9 digits.");
      setCredentials({ ...credentials, phone: credentials.phone });
    }
  };

  const handleSignup = async () => {
    setLoading(true);
    setSignupError('');

    if (credentials.firstname.length < 1 || credentials.lastname.length < 1) {
      setSignupError('First name and last name are required');
      return;
    }
    if (credentials.licenseNumber.length < 1) {
      setSignupError('Real estate license number is required');
      return;
    }

    let phoneDigits = credentials.phone.replace(/\s+/g, '');
    if (phoneDigits.startsWith('0')) {
      phoneDigits = phoneDigits.slice(1);
    }

    const isValid = /^\d{9}$/.test(phoneDigits);

    if (!isValid) {
      setSignupError(
        'Invalid phone number. Please enter a valid Australian phone number with 9 digits.'
      );
      return;
    }

    const fullPhoneNumber = '+61' + phoneDigits;

    try {
      await signup(credentials.email, credentials.password, credentials.firstname, credentials.lastname, credentials.role, credentials.licenseNumber, null, null, fullPhoneNumber);
      navigate('/search');
    }
    catch (error) {
      console.error('Error signing up: ', error);
      errorUtility(error)
    }

    setLoading(false);
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'var(--router-height)' }}>
        <ProperteeCircularProgress />
      </div>
    )
  }

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 3, my: 2 }}>
        <Typography variant="h4" align="center" sx={{ mb: 0 }}>
          Agent Sign Up
        </Typography>
        <Divider sx={{ my: 2, mx: 'auto', width: '100%' }} />
        <Typography variant="body2" align="center" sx={{ mb: 0 }}>
          This is the sign up page for real estate agents. For the sign up page for regular users, click <a href="/login">here</a>.
        </Typography>
        <Typography variant="body2" align="center" sx={{ color: '#FF0000', mt: 1 }}>
          Please ensure you have entered your license number correctly and send the required valid real estate license to <a href="mailto:admin@propertee.org">admin@propertee.org</a> for verification.
        </Typography>
        <TextField
          fullWidth
          label="First Name"
          name="firstname"
          value={credentials.firstname}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          margin="dense"
          required
        />
        <TextField
          fullWidth
          label="Last Name"
          name="lastname"
          value={credentials.lastname}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          margin="dense"
          required
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          value={credentials.email}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          margin="dense"
          required
        />
        <TextField
          fullWidth
          label="Phone Number"
          name="phone"
          value={credentials.phone}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={handlePhoneFocus}
          onBlur={handlePhoneBlur}
          margin="dense"
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">+61</InputAdornment>,
          }}
        />
        <TextField
          fullWidth
          label="Password"
          name="password"
          type="password"
          value={credentials.password}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          margin="dense"
          required
        />
        <TextField
          fullWidth
          label="Real Estate License Number"
          name="licenseNumber"
          value={credentials.licenseNumber}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          margin="dense"
          required
        />
        <Button fullWidth variant="containedAccent" disabled={loading} onClick={handleSignup} sx={{ mt: 1 }}>
          Sign Up
        </Button>
        <Typography variant="body2" align="center" sx={{ pt: 2, mb: 0 }}>
          By clicking sign up you are agreeing to our <a href="/terms">Terms of Use</a> and <a href="/privacy-policy">Privacy Policy</a>.
        </Typography>
        {signupError && (
          <Typography color="error" align="center" sx={{ mt: 2 }}>
            {signupError}
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default AgentSignUp;
