import React, { useContext } from 'react';
import { Box, Typography, Button, Paper, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import heroImage from '../assets/Hero-Image.jpg';
import AuthContext from '../utilities/AuthContext';

function HeroComponent() {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate(); // Initialize navigate

  // Determine button properties based on user status and role
  let buttonText;
  let buttonOnClick;
  let buttonDisabled = false;

  if (!currentUser) {
    // Unauthenticated user
    buttonText = 'Sign Up';
    buttonOnClick = () => navigate('/login');
  } else if (currentUser.role === 1) {
    // Authenticated user with role 1
    buttonText = 'Get Started';
    buttonOnClick = () => navigate('/search');
  } else if (currentUser.role === 0) {
    // Authenticated user with role 0
    buttonText = 'Welcome';
    buttonOnClick = () => { }; // No action
    buttonDisabled = true; // Disable the button
  } else {
    // Default case for other roles or undefined roles
    buttonText = 'Get Started';
    buttonOnClick = () => navigate('/agent-verification-approval');
  }

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        position: 'relative',
        height: 'var(--router-height)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.7) 100%), url(${heroImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          width: '90%',
          textAlign: 'center',
          p: { xs: 2, sm: 4 },
          color: 'common.white',
          mt: { xs: '10vh', sm: '20vh' },
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          sx={{ mb: 2, fontWeight: 'bold', fontSize: { xs: '2rem', sm: '3rem' } }}
        >
          Property Made Easy
        </Typography>
        <Typography
          variant="h5"
          component="p"
          sx={{ mb: 3, fontSize: { xs: '1rem', sm: '1.5rem' } }}
        >
          The property platform that simplifies the home buying and selling process.
          No more hassle. No more old-school way of doing things.
        </Typography>
      </Box>

      <Paper
        elevation={10}
        sx={{
          bgcolor: 'var(--accent-color)',
          color: 'common.white',
          p: { xs: 2, sm: 3 },
          borderRadius: '50px',
          maxWidth: '600px',
          textAlign: 'center',
          zIndex: 10,
          width: '80%',
          mb: { xs: '10vh', sm: '20vh' },
        }}
      >
        <Typography
          variant="h5"
          component="p"
          sx={{ mb: 2, fontSize: { xs: '1rem', sm: '1.5rem' } }}
        >
          Join the new way to buy and sell property.
        </Typography>
        <Button
          variant="outlined"
          sx={{ width: '35%' }}
          onClick={buttonOnClick}
          disabled={buttonDisabled}
        >
          {buttonText}
        </Button>
      </Paper>
    </Container>
  );
}

export default HeroComponent;
