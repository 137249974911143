import React from 'react';
import Features from '../components/Features';
import HomeContact from '../components/HomeContact';
import MovingText from '../components/MovingText';
import ApproachDescription from '../components/ApproachDescription';
import { Box } from '@mui/material';
import backgroundImage from '../assets/background-building.png';
import HeroComponent from '../components/HomeComponent';

const HomePage = () => {
  return (
    <div style={{ 
      scrollSnapType: 'y mandatory', 
      overflowY: 'scroll', 
      scrollBehavior: 'smooth',
      height: 'var(--router-height)' 
    }}>
      {/* HeroCompoent */}
      <Box sx={{ 
        height: 'var(--router-height)', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        scrollSnapAlign: 'start', 
        scrollSnapStop: 'always',
      }}>
        <HeroComponent />
      </Box>

      {/* Animated entry */}
      <Box sx={{
        height: 'var(--router-height)', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        scrollSnapAlign: 'start', 
        scrollSnapStop: 'always',
      }}>
        <MovingText />
      </Box>

      {/* Old way- New way description */}
      <Box sx={{ 
        height: 'var(--router-height)', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        scrollSnapAlign: 'start', 
        scrollSnapStop: 'always',
      }}>
        <ApproachDescription/>
      </Box>

      {/* Dynamic seciton */}
      <Box sx={{ 
        height: 'var(--router-height)', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        scrollSnapAlign: 'start', 
        scrollSnapStop: 'always',
      }}>
        <Features />
      </Box>

      {/* contact us component */}
      <Box sx={{
        height: 'var(--router-height)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        scrollSnapAlign: 'start',
        backgroundImage: `url(${backgroundImage})`, // Use imported image
        backgroundSize: 'cover',
        scrollSnapStop: 'always',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}>
        <HomeContact />
      </Box>
    </div>
  );
}

export default HomePage;
