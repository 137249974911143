import { React } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';

import Navbar from './components/Navbar';
import MaintenanceNavbar from './components/MaintenanceNavbar';
import Footer from './components/Footer';
import LoginPage from './pages/LoginPage';

import AboutPage from './pages/AboutPage'
import MaintenancePage from './pages/MaintenancePage';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import UserPage from './pages/UserPage';
import ContactPage from './pages/ContactPage';
import SearchPage from './pages/SearchPage';
import AgentSignUp from './pages/AgentSignUp';
import NotAuthenticated from './pages/NotAuthenticated';
import EventPage from './pages/EventPage';
import AgentVerificationApproval from './pages/AgentVerificationApproval';
import PaymentsPage from './pages/PaymentPage';
import SuccessPage from './pages/SuccessPage';
import CancellationPage from './pages/CancellationPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsPage from './pages/TermsPage';
import CheckIn from './pages/CheckIn';
import AuthProvider from './utilities/AuthProvider';
import ProtectedRoute from './utilities/ProtectedRoute';
import AuthenticatedRedirect from './utilities/AuthenticatedRedirect';

function App() {
  const maintenanceMode = false;

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Router>
          {maintenanceMode ? <MaintenanceNavbar /> : <Navbar />}
          <div className="app-container">
            <div className="router-content">
              <Routes>
                {maintenanceMode ? (
                  <Route path="*" element={<MaintenancePage />} />
                ) : (
                  <>
                    <Route exact path="/" element={<HomePage />} />

                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms" element={<TermsPage />} />
                    <Route path="/events" element={<ProtectedRoute component={EventPage} requiredRole={1} />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/user" element={<ProtectedRoute component={UserPage} />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/agent-signup" element={<AuthenticatedRedirect component={AgentSignUp} />} />
                    <Route path="/search" element={<ProtectedRoute component={SearchPage} requiredRole={1} />} />
                    <Route path="/check-in/:homeOpenId" element={<ProtectedRoute component={CheckIn} />} />
                    <Route path="/not-authorized" element={<ProtectedRoute component={NotAuthenticated} />} />
                    <Route path="/agent-verification-approval" element={<ProtectedRoute component={AgentVerificationApproval} requiredRole={99} />} />
                    <Route path="/payments" element={<ProtectedRoute component={PaymentsPage} requiredRole={1} />} />
                    <Route path="/success" element={<ProtectedRoute component={SuccessPage} requiredRole={1} />} />
                    <Route path="/cancel" element={<ProtectedRoute component={CancellationPage} requiredRole={1} />} />

                    <Route path="*" element={<NotFoundPage />} />
                  </>
                )}
              </Routes>
            </div>
          </div>
          <Footer className="footer" />
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
