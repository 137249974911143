import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const NotAuthenticated = () => {
  
  return (
    <div style={{ textAlign: 'center' }}>
        <Container maxWidth="md">
            <Box my={4}>
                <Typography variant="h4" >
                    Not Authorized to View This Page
                </Typography>
            </Box>
            <Box my={4}>
                <Typography variant="h6" >
                    Navigate to another page or talk to your admin if you think this is a mistake.
                </Typography>
            </Box>
        </Container>
    </div>
  );
};

export default NotAuthenticated;



