import React, { useState, useContext } from 'react';
import AuthContext from '../utilities/AuthContext';
import UploadControlBar from '../components/UploadControlBar';
import {
  Typography,
  Grid,
  Box,
  Divider,
  TextField,
  Paper,
  Snackbar,
  Alert,
  Slide,
  RadioGroup,
  FormControlLabel,
  Radio,
  Backdrop,
  LinearProgress,
} from '@mui/material';
import BedroomIcon from '@mui/icons-material/Bed';
import BathroomIcon from '@mui/icons-material/Bathtub';
import ParkingIcon from '@mui/icons-material/LocalParking';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ImageSliderUpload from '../components/ImageSliderUpload';
import EditDescriptionCard from '../components/EditDescriptionCard';
import { createProperty, updatePropertyImages, deleteProperty } from '../utilities/FirestoreFunctions';
import { uploadImage, uploadMultipleImages } from '../utilities/StorageFunctions';
import PlacesAutocomplete from './PlacesAutocomplete';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const CreateHome = ({ setCurrentComponent, addProperty }) => {
  const initialPropertyData = {
    images: [],
    address: '',
    bedrooms: 0,
    bathrooms: 0,
    parking: 0,
    price: '',
    priceParameter: '=',
    listingType: '1',
    title: '',
    shortDescription: '',
    description: '',
  };

  const { currentUser } = useContext(AuthContext);
  const [propertyData, setPropertyData] = useState(initialPropertyData);
  const [descriptionIsEmpty, setDescriptionIsEmpty] = useState(true);
  const [editingField, setEditingField] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleRadioChange = (event, field) => {
    setPropertyData((prevData) => ({
      ...prevData,
      [field]: event.target.value,
    }));
  };

  const handleStartEditing = (field) => {
    setEditingField(field);
  };

  const handleCloseAutocomplete = () => {
    setEditingField(null);
  };

  const formatPriceDisplay = (price) => {
    return price ? `$${price.toLocaleString()}` : '';
  };

  const handleInputChange = (event, field) => {
    let value = event.target.value;

    if (['bedrooms', 'bathrooms', 'parking'].includes(field)) {
      value = value === '' ? '' : Math.max(0, parseInt(value) || 0);
    } else if (field === 'price') {
      value = parseInt(value.replace(/[^0-9]/g, '')) || '';
    } else {
      value = event.target.value;
    }

    setPropertyData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleBlur = (field) => {
    if (
      ['bedrooms', 'bathrooms', 'parking'].includes(field) &&
      propertyData[field] === ''
    ) {
      setPropertyData((prevData) => ({ ...prevData, [field]: 0 }));
    } else if (field === 'price' && propertyData[field] === '') {
      setPropertyData((prevData) => ({ ...prevData, [field]: 0 }));
    }
    handleConfirmChanges();
  };

  const handleSaveDescription = (editedDescription) => {
    setPropertyData((prevData) => ({
      ...prevData,
      description: editedDescription,
    }));
    setDescriptionIsEmpty(editedDescription.trim() === '');
    handleConfirmChanges();
  };

  const handleConfirmChanges = () => {
    setEditingField(null);
  };

  const handleImageChanges = (newImages) => {
    setPropertyData((prevData) => ({
      ...prevData,
      images: newImages,
    }));
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (
        ['bedrooms', 'bathrooms', 'parking'].includes(editingField) &&
        propertyData[editingField] === ''
      ) {
        setPropertyData((prevData) => ({ ...prevData, [editingField]: 0 }));
      } else if (editingField === 'price' && propertyData[editingField] === '') {
        setPropertyData((prevData) => ({ ...prevData, [editingField]: 0 }));
      }
      handleConfirmChanges();
    }
  };

  const handleSaveProperty = async () => {
    if (descriptionIsEmpty) {
      setSnackbar({
        open: true,
        message: 'Description cannot be empty',
        severity: 'warning',
      });
      return;
    } else {
      let propertyId = null;
      setLoading(true);
      setIsUploading(true);
      setUploadProgress(0);

      try {
        propertyId = await createProperty(currentUser.uid, propertyData);

        let imageUrls = [];

        if (propertyData.images.length === 0) {
          imageUrls = [];
        } else if (propertyData.images.length === 1) {
          imageUrls.push(
            await uploadImage(propertyData.images[0], propertyId, (progress) => {
              setUploadProgress(progress);
            })
          );
        } else if (propertyData.images.length > 1) {
          imageUrls = await uploadMultipleImages(
            propertyData.images,
            propertyId,
            (progress) => {
              setUploadProgress(progress);
            }
          );
        }

        await updatePropertyImages(propertyId, imageUrls);

        const newProperty = {
          ...propertyData,
          id: propertyId,
          images: imageUrls,
          imgUrl: imageUrls[0] || '',
          firstImageObject: propertyData.images[0],
        };

        addProperty(newProperty);

        setSnackbar({
          open: true,
          message: 'Property created successfully!',
          severity: 'success',
        });
        setTimeout(() => {
          setCurrentComponent('PropertyListing');
        }, 5000);
      } catch (error) {
        console.error('Error saving property: ', error);
        if (propertyId) {
          await deleteProperty(propertyId);
        }
        setSnackbar({
          open: true,
          message: 'Failed to create property.',
          severity: 'error',
        });
      } finally {
        setIsUploading(false);
        setLoading(false);
      }
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }

  const isEditing = editingField !== null;

  const blueLineStyle = {
    borderBottom: '2px solid var(--accent-color)',
    width: '100%',
    marginTop: '8px',
    marginBottom: '8px',
  };

  return (
    <div>
      <UploadControlBar
        onBack={() => setCurrentComponent('')}
        onUpload={() => handleSaveProperty()}
      />
      {/* Main Grid Container */}
      <Grid container >
        {/* Left Side (Images, Title, Description) */}
        <Grid
          item
          xs={12}
          md={9}
          order={{ xs: 1, md: 1 }}
          sx={{
            borderRight: { md: '2px solid var(--accent-color)' },
            borderBottom: { xs: '2px solid var(--accent-color)', md: 'none' },
          }}
        >
          <Grid container direction="column" spacing={2}>
            {/* Images */}
            <Grid item>
              <ImageSliderUpload
                images={propertyData.images}
                setNewImages={handleImageChanges}
              />
            </Grid>
            {/* Title and Short Description */}
            <Grid item>
              <Paper
                elevation={0}
                sx={{
                  position: 'relative',
                  maxWidth: 1000,
                  margin: 'auto',
                  backgroundColor: 'transparent',
                  border: 'none',
                  padding: 3
                }}
              >
                <Grid container direction="column" spacing={0}>
                  {/* Title */}
                  <Grid item>
                    {editingField === 'title' ? (
                      <TextField
                        fullWidth
                        value={propertyData.title}
                        onChange={(e) => handleInputChange(e, 'title')}
                        onBlur={handleConfirmChanges}
                        onKeyPress={handleKeyPress}
                        autoFocus
                        error={!propertyData.title.trim() && isEditing}
                        helperText={
                          !propertyData.title.trim() && isEditing
                            ? 'Title cannot be empty'
                            : ''
                        }
                        size="small"
                        sx={{
                          '& .MuiInputBase-input': {
                            fontSize: '2rem',
                            fontWeight: 'bold',
                          },
                        }}
                      />
                    ) : (
                      <Typography
                        variant="h3"
                        onClick={() => handleStartEditing('title')}
                        sx={{
                          color:
                            !propertyData.title.trim() && !isEditing
                              ? 'var(--warning)'
                              : 'inherit',
                          cursor: 'pointer',
                          fontWeight: 'bold',
                          marginBottom: '8px',
                        }}
                      >
                        {!propertyData.title
                          ? 'Click to add a title'
                          : propertyData.title}
                      </Typography>
                    )}
                  </Grid>

                  {/* Short Description */}
                  <Grid item>
                    {editingField === 'shortDescription' ? (
                      <TextField
                        fullWidth
                        value={propertyData.shortDescription}
                        onChange={(e) => handleInputChange(e, 'shortDescription')}
                        onBlur={handleConfirmChanges}
                        onKeyPress={handleKeyPress}
                        autoFocus
                        error={!propertyData.shortDescription.trim() && isEditing}
                        helperText={
                          !propertyData.shortDescription.trim() && isEditing
                            ? 'Short description cannot be empty'
                            : ''
                        }
                        size="small"
                        sx={{
                          '& .MuiInputBase-input': {
                            fontSize: '1.25rem',
                            color: 'text.secondary',
                          },
                        }}
                      />
                    ) : (
                      <Typography
                        variant="h5"
                        onClick={() => handleStartEditing('shortDescription')}
                        sx={{
                          color:
                            !propertyData.shortDescription.trim() && !isEditing
                              ? 'var(--warning)'
                              : 'text.secondary',
                          cursor: 'pointer',
                        }}
                      >
                        {!propertyData.shortDescription
                          ? 'Click to add a short description'
                          : propertyData.shortDescription}
                      </Typography>
                    )}
                  </Grid>

                  {/* Blue Line */}
                  <div style={blueLineStyle}></div>
                </Grid>
              </Paper>
            </Grid>
            {/* Description */}
            <Grid item>
              <EditDescriptionCard
                property={propertyData}
                onSaveDescription={handleSaveDescription}
                onEditAddress={() => handleStartEditing('address')}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Right Side (Property Info) */}
        <Grid
          item
          xs={12}
          md={3}
          order={{ xs: 2, md: 2 }}
          sx={{
            position: { md: 'sticky' },
            alignSelf: 'flex-start',
            padding: 3
          }}
        >
          <Box className="property-info">
            {/* Address */}
            <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
              <LocationOnIcon sx={{ mr: 1 }} />
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Address
              </Typography>
            </Box>
            {editingField === 'address' ? (
              <PlacesAutocomplete
                setPropertyData={setPropertyData}
                closeAutocomplete={handleCloseAutocomplete}
              />
            ) : (
              <Typography
                variant="body1"
                onClick={() => handleStartEditing('address')}
                sx={{
                  color:
                    !propertyData.address.trim() && !isEditing
                      ? 'var(--warning)'
                      : 'inherit',
                  cursor: 'pointer',
                }}
              >
                {!propertyData.address
                  ? 'Click to select address'
                  : propertyData.address}
              </Typography>
            )}
            <Divider
              style={{
                margin: '16px 0',
                borderColor: 'var(--accent-color)',
              }}
            />
            {/* Listing Type */}
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Listing Type
            </Typography>
            <RadioGroup
              row
              name="listingType"
              value={propertyData.listingType}
              onChange={(e) => handleRadioChange(e, 'listingType')}
            >
              <FormControlLabel value="1" control={<Radio />} label="For Sale" />
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="Casual Listing"
              />
              <FormControlLabel value="-1" control={<Radio />} label="Off Market" />
            </RadioGroup>
            <Divider
              style={{ margin: '16px 0', borderColor: 'var(--accent-color)' }}
            />
            {/* Price Parameters */}
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Price Parameters
            </Typography>
            <RadioGroup
              row
              name="priceParameter"
              value={propertyData.priceParameter}
              onChange={(e) => handleRadioChange(e, 'priceParameter')}
            >
              <FormControlLabel
                value="="
                control={<Radio />}
                label="Listed price"
              />
              <FormControlLabel
                value=">"
                control={<Radio />}
                label="Offers higher than"
              />
              <FormControlLabel
                value="*"
                control={<Radio />}
                label="Open to offers"
              />
            </RadioGroup>
            <Divider
              style={{ margin: '16px 0', borderColor: 'var(--accent-color)' }}
            />
            {/* Price */}
            {/* {editingField === 'price' ? ( */}
            {/*   <TextField */}
            {/*     type="number" */}
            {/*     inputProps={{ min: 0 }} */}
            {/*     value={propertyData.price.toString()} */}
            {/*     onChange={(e) => handleInputChange(e, 'price')} */}
            {/*     onBlur={() => handleBlur('price')} */}
            {/*     onKeyPress={handleKeyPress} */}
            {/*     autoFocus */}
            {/*     error={propertyData.price === '' && isEditing} */}
            {/*     helperText={ */}
            {/*       propertyData.price === '' && isEditing */}
            {/*         ? 'Price cannot be empty' */}
            {/*         : '' */}
            {/*     } */}
            {/*     style={{ */}
            {/*       color: */}
            {/*         propertyData.price === '' && !isEditing */}
            {/*           ? 'var(--warning)' */}
            {/*           : 'inherit', */}
            {/*       borderColor: 'var(--accent-color)', */}
            {/*     }} */}
            {/*   /> */}
            {/* ) : ( */}
            {/*   <Typography */}
            {/*     variant="h4" */}
            {/*     gutterBottom */}
            {/*     onClick={() => handleStartEditing('price')} */}
            {/*     style={{ */}
            {/*       color: */}
            {/*         propertyData.price === '' && */}
            {/*         !isEditing && */}
            {/*         propertyData.priceParameter !== '*' */}
            {/*           ? 'var(--warning)' */}
            {/*           : 'inherit', */}
            {/*       cursor: 'pointer', */}
            {/*     }} */}
            {/*   > */}
            {/*     {propertyData.price === '' && */}
            {/*     propertyData.priceParameter !== '*' ? ( */}
            {/*       'Click to add price.' */}
            {/*     ) : propertyData.priceParameter === '*' ? ( */}
            {/*       'Open to offers' */}
            {/*     ) : ( */}
            {/*       formatPriceDisplay(propertyData.price) */}
            {/*     )} */}
            {/*   </Typography> */}
            {/* )} */}
            {/* <Divider */}
            {/*   style={{ */}
            {/*     margin: '16px 0', */}
            {/*     borderColor: 'var(--accent-color)', */}
            {/*   }} */}
            {/* /> */}
            {/* price */}
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 1 }}
            >
              <AttachMoneyIcon sx={{ mr: 1 }} />
              Price
            </Typography>
            {editingField === 'price' && propertyData.priceParameter !== '*' ? (
              <TextField
                type="number"
                inputProps={{ min: 0 }}
                value={propertyData.price !== '' ? propertyData.price.toString() : '0'}
                onChange={(e) => handleInputChange(e, 'price')}
                onBlur={() => {
                  handleBlur('price');
                  if (propertyData.price === '') {
                    handleInputChange({ target: { value: '0' } }, 'price'); 
                  }
                }}
                onKeyPress={handleKeyPress}
                autoFocus
                error={propertyData.price === '' && isEditing}
                helperText={
                  propertyData.price === '' && isEditing ? 'Price cannot be empty' : ''
                }
                size="small"
                style={{
                  width:'100%',
                  color:
                    propertyData.price === '' && !isEditing ? 'var(--warning)' : 'inherit',
                  borderColor: propertyData.price === '' ? 'var(--warning)' : 'var(--accent-color)',
                }}
              />
            ) : (
              <Typography
                onClick={() => handleStartEditing('price')}
                style={{
                  color:
                    (propertyData.price === '' || propertyData.price === '0') &&
                    !isEditing &&
                    propertyData.priceParameter !== '*'
                      ? 'var(--warning)'
                      : 'inherit',
                  cursor: 'pointer',
                }}
              >
                {(propertyData.price === '' || propertyData.price === '0') &&
                propertyData.priceParameter !== '*' ? (
                  'Click to add price.'
                ) : propertyData.priceParameter === '*' ? (
                  'Open to offers'
                ) : (
                  formatPriceDisplay(propertyData.price)
                )}
              </Typography>
            )}
            <Divider
              style={{
                margin: '16px 0',
                borderColor: 'var(--accent-color)',
              }}
            />
            {/* Bedrooms */}
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 1 }}
            >
              <BedroomIcon sx={{ mr: 1 }} />
              Bedrooms
            </Typography>
            {editingField === 'bedrooms' ? (
              <TextField
                type="number"
                inputProps={{ min: 0 }}
                value={propertyData.bedrooms}
                onChange={(e) => handleInputChange(e, 'bedrooms')}
                onBlur={() => handleBlur('bedrooms')}
                onKeyPress={handleKeyPress}
                autoFocus
                error={propertyData.bedrooms === '' && isEditing}
                helperText={
                  propertyData.bedrooms === '' && isEditing
                    ? 'Bedrooms cannot be empty'
                    : ''
                }
                size="small"
                style={{
                  width: '100%',
                  color:
                    propertyData.bedrooms === '' && !isEditing
                      ? 'var(--warning)'
                      : 'inherit',
                  borderColor:
                    propertyData.bedrooms === '' ? 'var(--warning)' : 'var(--accent-color)',
                }}
              />
            ) : (
              <Typography
                onClick={() => handleStartEditing('bedrooms')}
                style={{
                  color:
                    propertyData.bedrooms === '' ? 'var(--warning)' : 'inherit',
                  cursor: 'pointer',
                }}
              >
                {propertyData.bedrooms === ''
                  ? 'Click to enter bedrooms'
                  : `${propertyData.bedrooms} ${
                      propertyData.bedrooms === 1 ? 'Bed' : 'Beds'
                    }`}
              </Typography>
            )}
            <Divider
              style={{
                margin: '16px 0',
                borderColor: 'var(--accent-color)',
              }}
            />
            {/* Bathrooms */}
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 1 }}
            >
              <BathroomIcon sx={{ mr: 1 }} />
              Bathrooms
            </Typography>
            {editingField === 'bathrooms' ? (
              <TextField
                type="number"
                inputProps={{ min: 0 }}
                value={propertyData.bathrooms}
                onChange={(e) => handleInputChange(e, 'bathrooms')}
                onBlur={() => handleBlur('bathrooms')}
                onKeyPress={handleKeyPress}
                autoFocus
                error={propertyData.bathrooms === '' && isEditing}
                helperText={
                  propertyData.bathrooms === '' && isEditing
                    ? 'Bathrooms cannot be empty'
                    : ''
                }
                size="small"
                style={{
                  width: '100%',
                  color:
                    propertyData.bathrooms === '' && !isEditing
                      ? 'var(--warning)'
                      : 'inherit',
                  borderColor:
                    propertyData.bathrooms === '' ? 'var(--warning)' : 'var(--accent-color)',
                }}
              />
            ) : (
              <Typography
                onClick={() => handleStartEditing('bathrooms')}
                style={{
                  color:
                    propertyData.bathrooms === '' ? 'var(--warning)' : 'inherit',
                  cursor: 'pointer',
                }}
              >
                {propertyData.bathrooms === ''
                  ? 'Click to enter bathrooms'
                  : `${propertyData.bathrooms} ${
                      propertyData.bathrooms === 1 ? 'Bath' : 'Baths'
                    }`}
              </Typography>
            )}
            <Divider
              style={{
                margin: '16px 0',
                borderColor: 'var(--accent-color)',
              }}
            />
            {/* Parking Spots */}
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 1 }}
            >
              <ParkingIcon sx={{ mr: 1 }} />
              Parking Spots
            </Typography>
            {editingField === 'parking' ? (
              <TextField
                type="number"
                inputProps={{ min: 0 }}
                value={propertyData.parking}
                onChange={(e) => handleInputChange(e, 'parking')}
                onBlur={() => handleBlur('parking')}
                onKeyPress={handleKeyPress}
                autoFocus
                error={propertyData.parking === '' && isEditing}
                helperText={
                  propertyData.parking === '' && isEditing
                    ? 'Parking spots cannot be empty'
                    : ''
                }
                size= "small"
                style={{
                  width: '100%',
                  color:
                    propertyData.parking === '' && !isEditing
                      ? 'var(--warning)'
                      : 'inherit',
                  borderColor:
                    propertyData.parking === '' ? 'var(--warning)' : 'var(--accent-color)',
                }}
              />
            ) : (
              <Typography
                onClick={() => handleStartEditing('parking')}
                style={{
                  color:
                    propertyData.parking === '' ? 'var(--warning)' : 'inherit',
                  cursor: 'pointer',
                }}
              >
                {propertyData.parking === ''
                  ? 'Click to enter parking spots'
                  : `${propertyData.parking} Parking`}
              </Typography>
            )}
            <Divider
              style={{
                margin: '16px 0',
                borderColor: 'var(--accent-color)',
              }}
            />
          </Box>
        </Grid>
      </Grid>
      {/* Backdrop and Snackbar */}
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        }}
        open={isUploading || loading}
      >
        <div style={{ width: '80%', textAlign: 'center' }}>
          <Typography variant="h6" color="#ffffff">
            Uploading images...
          </Typography>
          <LinearProgress
            variant="determinate"
            value={uploadProgress}
            sx={{
              mt: 2,
              color: '#ffffff',
              '& .MuiLinearProgress-bar': {
                backgroundColor: 'accent.main',
              },
            }}
          />
          <Typography variant="body1" sx={{ mt: 1, color: '#ffffff' }}>
            {Math.round(uploadProgress)}%
          </Typography>
        </div>
      </Backdrop>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CreateHome;
