
import React from 'react';
import { Box, Container, Typography, Paper, Divider, Link } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        height: 'var(--router-height)',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            my: 2,
          }}
        >
          <Typography variant="h4" gutterBottom>
            Privacy Statement
          </Typography>
          <Divider sx={{ mb: 2 }} />

          <Typography variant="body1" paragraph>
            BNK Technology Pty Ltd ("BNK Technology") owns, operates, and provides the Propertee webapp (“Propertee”). We respect your privacy and
            are committed to complying with the Australian Privacy Act 1988 (Cth) and the Australian Privacy Principles (APPs). This Privacy Policy
            outlines how we collect, use, disclose, and protect your personal information. By using Propertee, you agree to the terms of this
            Privacy Policy.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            1. Collection of Personal Information
          </Typography>
          <Typography variant="body1" paragraph>
            We collect personal information in the following ways:
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>1.1 Using Propertee as an Agent:</strong> When creating an Agent account, we may collect:
          </Typography>
          <Typography variant="body1" paragraph>
            • Name
          </Typography>
          <Typography variant="body1" paragraph>
            • Age
          </Typography>
          <Typography variant="body1" paragraph>
            • Gender
          </Typography>
          <Typography variant="body1" paragraph>
            • Mailing address
          </Typography>
          <Typography variant="body1" paragraph>
            • Email address
          </Typography>
          <Typography variant="body1" paragraph>
            • Phone number
          </Typography>
          <Typography variant="body1" paragraph>
            • Real estate agent number/licence
          </Typography>
          <Typography variant="body1" paragraph>
            • Credit card information
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>1.2 Using Propertee as a General User:</strong> When creating a User account, we may collect:
          </Typography>
          <Typography variant="body1" paragraph>
            • Name
          </Typography>
          <Typography variant="body1" paragraph>
            • Age
          </Typography>
          <Typography variant="body1" paragraph>
            • Gender
          </Typography>
          <Typography variant="body1" paragraph>
            • Email address
          </Typography>
          <Typography variant="body1" paragraph>
            • Phone number
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>1.3 Using Propertee as a Guest:</strong> When you use the check-in feature at home opens without creating an account, we may collect:
          </Typography>
          <Typography variant="body1" paragraph>
            • Name
          </Typography>
          <Typography variant="body1" paragraph>
            • Email address
          </Typography>
          <Typography variant="body1" paragraph>
            • Phone number
          </Typography>
          <Typography variant="body1" paragraph>
            This information will be available to the Agent managing the home open and our Administrator Team.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>1.4 Cookies/IP Addresses:</strong>
          </Typography>
          <Typography variant="body1" paragraph>
            Propertee uses cookies and collects IP addresses to enhance your user experience. Cookies are small data files stored on your device that
            help us keep your session active and personalize content. You can adjust your browser settings to refuse cookies, but this may affect the
            functionality of Propertee.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            2. Use of Personal Information
          </Typography>
          <Typography variant="body1" paragraph>
            We use your personal information to:
          </Typography>
          <Typography variant="body1" paragraph>
            • Provide and manage our services
          </Typography>
          <Typography variant="body1" paragraph>
            • Facilitate seamless check-in and home open experiences
          </Typography>
          <Typography variant="body1" paragraph>
            • Communicate with you regarding account verification, offers made, or check-in records
          </Typography>
          <Typography variant="body1" paragraph>
            • Process transactions and billing
          </Typography>
          <Typography variant="body1" paragraph>
            • Improve our services, marketing efforts, and website functionality
          </Typography>
          <Typography variant="body1" paragraph>
            • Customize website content and layout
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            3. Disclosure of Personal Information
          </Typography>
          <Typography variant="body1" paragraph>
            We may disclose your personal information to:
          </Typography>
          <Typography variant="body1" paragraph>
            • Third-party service providers assisting us in delivering our services
          </Typography>
          <Typography variant="body1" paragraph>
            • Agents and real estate agencies involved in your home open check-ins and offers
          </Typography>
          <Typography variant="body1" paragraph>
            • Legal authorities if required by law or legal process
          </Typography>
          <Typography variant="body1" paragraph>
            We will not disclose your personal information to third parties for marketing purposes without your consent.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            4. Security of Personal Information
          </Typography>
          <Typography variant="body1" paragraph>
            We take reasonable steps to protect your personal information from misuse, interference, loss, unauthorized access, modification, or
            disclosure. We use industry-standard encryption technology to secure your data during transmission and store it on password-protected
            servers in restricted access areas.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            5. Access to and Correction of Personal Information
          </Typography>
          <Typography variant="body1" paragraph>
            You have the right to access and correct the personal information we hold about you. To request access or make corrections, please contact
            us via the "Contact Us" facility on our webapp. We will respond to your request within a reasonable timeframe.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            6. Data Breaches
          </Typography>
          <Typography variant="body1" paragraph>
            In the event of a data breach that is likely to result in serious harm, we will notify the Office of the Australian Information Commissioner
            (OAIC) and affected individuals as required by law. Our Security Team actively monitors and responds to any data breaches. If you suspect a
            breach of your personal information, please contact us immediately.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            7. Links to Other Websites
          </Typography>
          <Typography variant="body1" paragraph>
            Propertee may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites.
            We recommend reviewing the privacy policies of any third-party websites you visit.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            8. Changes to This Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph>
            We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any changes will be posted on
            this page. We encourage you to review this Privacy Policy periodically.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            9. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions or concerns about this Privacy Policy or how we handle your personal information, please contact us:
          </Typography>
          <Typography variant="body1" paragraph>
            Email: 
            <Link
              href="mailto:admin@propertee.org"
              color="inherit"
              underline="hover"
              sx={{ ml: 1 }}
            >
              admin@propertee.org
            </Link>
          </Typography>
          <Typography variant="body1" paragraph>
            Mailing Address: Level 5, 600 Murray Street, West Perth WA 6005
          </Typography>

          <Divider sx={{ mb: 2 }} />
          <Typography variant="body2" gutterBottom sx={{ mt: 4 }}>
            Last Updated: 29th October 2024
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;

